import { ReactNode } from 'react';

import { Paper } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

interface FormsPaperProps {
  children: ReactNode;
  [x: string]: unknown;
}

export default function FormsPaper({
  children,
  ...otherProps
}: FormsPaperProps) {
  // ==========================================================================
  // General
  // ==========================================================================
  const smallScreen = useMediaQuery('(max-width: 35em)');

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <Paper p={smallScreen ? '1.3em' : '2em'} {...otherProps}>
      {children}
    </Paper>
  );
}
