import { Link, NavLink } from 'react-router-dom';

import {
  ActionIcon,
  Box,
  CopyButton,
  Group,
  Menu,
  rem,
  ScrollArea,
  Text,
  ThemeIcon,
  Tooltip,
} from '@mantine/core';
import { openModal } from '@mantine/modals';
import {
  IconCheck,
  IconChevronRight,
  IconCopy,
  IconKey,
  IconLogout,
  IconSettings,
  IconUserCircle,
} from '@tabler/icons-react';

import env from '@config/env';

import { useGetNotificationsCountQuery } from '@api/notifications.api';

import useAuth from '@hooks/useAuth';
import useUserRoleGuard from '@hooks/useUserRoleGuard';

import EditUserPasswordForm from '@components/EditUserPasswordForm';
import IconBadge from '@components/iconBadge/IconBadge';

import logo from '@assets/images/logo.svg';

import { linksData } from '../../../data/linksData';
import classes from './Sidebar.module.css';

export default function Sidebar() {
  const { user, logout } = useAuth();

  const userIsClient = useUserRoleGuard('client');
  const userIsSeller = useUserRoleGuard('seller');
  const userIsAdmin = useUserRoleGuard('admin');

  const { data: notificationsCount = { count: 0 } } =
    useGetNotificationsCountQuery('notRead');

  const handleEditPasswordClick = () => {
    openModal({
      title: 'Modifica password',
      children: <EditUserPasswordForm />,
    });
  };

  const links = linksData
    .filter(
      (item) =>
        (item.roles?.includes('client') && userIsClient) ||
        (item.roles?.includes('seller') && userIsSeller) ||
        (item.roles?.includes('admin') && userIsAdmin),
    )
    .map((item) => (
      <NavLink key={item.label} to={item.link} className={classes.link}>
        <Group justify="space-between" gap={0}>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <ThemeIcon variant="light" size={30}>
              <item.icon style={{ width: rem(18), height: rem(18) }} />
            </ThemeIcon>
            <Box ml="md">{item.label}</Box>
          </Box>
        </Group>
      </NavLink>
    ));

  return (
    <nav className={classes.sidebar}>
      <div className={classes.header}>
        <a href="https://www.tuoteam.com" target="_blank">
          <img src={logo} alt="Logo Tuoteam" style={{ width: rem(180) }} />
        </a>
        <IconBadge
          tooltipLabel="Notifiche"
          badgeText={
            notificationsCount.count !== 0
              ? notificationsCount.count.toString()
              : null
          }
          to="/notifiche"
        />
      </div>

      <ScrollArea className={classes.links}>
        <div className={classes.linksInner}>{links}</div>
      </ScrollArea>

      {user?.sellerCode && (
        <>
          <Text size="xs" mb="xs">
            Condividi il tuo codice società o il link affiliato per invitare i
            tuoi associati a registrarsi sul sito Tuoteam
          </Text>
          <Text mt="sm" size="sm">
            Codice società:
          </Text>
          <Group gap="xs">
            <Text fw="bold" size="sm">
              {user.sellerCode}
            </Text>
            <CopyButton value={user.sellerCode} timeout={2000}>
              {({ copied, copy }) => (
                <Tooltip
                  label={copied ? 'Copiato' : 'Copia'}
                  withArrow
                  position="right"
                >
                  <ActionIcon
                    color={copied ? 'teal' : 'gray'}
                    variant="subtle"
                    onClick={copy}
                  >
                    {copied ? (
                      <IconCheck style={{ width: rem(16) }} />
                    ) : (
                      <IconCopy style={{ width: rem(16) }} />
                    )}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
          </Group>

          <Text mt="sm" size="sm">
            Link affiliato
          </Text>
          <Group gap="xs">
            <Text fw="bold" size="sm">
              {env.BASE_URL}/registrati?sc=
              {encodeURIComponent(user.sellerCode)}
            </Text>
            <CopyButton
              value={`${env.BASE_URL}/registrati?sc=
                    ${encodeURIComponent(user.sellerCode)}`}
              timeout={2000}
            >
              {({ copied, copy }) => (
                <Tooltip
                  label={copied ? 'Copiato' : 'Copia'}
                  withArrow
                  position="right"
                >
                  <ActionIcon
                    color={copied ? 'teal' : 'gray'}
                    variant="subtle"
                    onClick={copy}
                  >
                    {copied ? (
                      <IconCheck style={{ width: rem(16) }} />
                    ) : (
                      <IconCopy style={{ width: rem(16) }} />
                    )}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
          </Group>
        </>
      )}

      <div className={classes.footer}>
        <Menu position="right-end" withArrow>
          <Menu.Target>
            <Group wrap="nowrap" className={classes.user}>
              <IconUserCircle size="2rem" />

              <div style={{ flex: 1 }}>
                <Text size="sm" fw={500}>
                  {user?.name}
                </Text>

                <Text c="dimmed" size="xs">
                  {user?.email}
                </Text>
              </div>

              <IconChevronRight
                style={{ width: rem(14), height: rem(14) }}
                stroke={1.5}
              />
            </Group>
          </Menu.Target>
          <Menu.Dropdown>
            {user!.role === 'client' && (
              <Menu.Item component={Link} to="/account">
                <Group>
                  <IconSettings size="1.5rem" />
                  <Text c="black" size="sm">
                    Account
                  </Text>
                </Group>
              </Menu.Item>
            )}
            <Menu.Item onClick={handleEditPasswordClick}>
              <Group>
                <IconKey size="1.5rem" />
                <Text size="sm">Modifica password</Text>
              </Group>
            </Menu.Item>
            <Menu.Item onClick={logout} c="red">
              <Group>
                <IconLogout size="1.5rem" />
                <Text size="sm">Logout</Text>
              </Group>
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </div>
    </nav>
  );
}
