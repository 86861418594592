import { SortOrder } from '@domain/types';

import { CountResponse } from '@interfaces/common.interface';
import {
  AffiliatedOrder,
  Order,
  OrderProduct,
  PaypalOrder,
  StripePaymentIntent,
} from '@interfaces/orders.interface';

import api from './';

export type GetOrdersSortBy = 'id' | 'date';

export type GetOrdersProductsSortBy = 'sku' | 'name';

export interface GetOrdersRequestParams {
  sortBy?: GetOrdersSortBy;
  sortOrder?: SortOrder;
  searchQuery?: string;
  page?: number;
  pageLength?: number;
}

export interface GetOrderProductRequest {
  id: string;
  params: GetOrdersRequestParams;
}

export interface OrderProductsCountApiQueryParams {
  id: string;
  searchQuery?: string;
}

export interface ConfirmOrderRequest {
  id: string;
}

export interface ConfirmPaymentTypeRequest {
  id: string;
}

export interface ConfirmProductGraphicRequest {
  id: string;
}

export interface CreateStripeOrderRequest {
  id: string;
}

export interface CreateStripeOrderResponse {
  clientSecret: string;
}

export interface CreatePaypalOrderRequest {
  id: string;
}

export interface CreatePaypalOrderResponse {
  id: string;
}

export interface ConfirmPaypalPaymentRequest {
  id: string;
  body: {
    paypalOrderId: string;
  };
}

export interface ConfirmPaypalPaymentResponse {
  message: string;
}

export interface UpdateOrderRequest {
  id: string;
  body: {
    shippingAddress: string;
    shippingCity: string;
    shippingZip: string;
    shippingProvince: string;
  };
}

interface GetWebOrderIdResponse {
  id: string;
}

export const {
  useGetOrdersQuery,
  useGetOrdersCountQuery,
  useGetSingleOrderQuery,
  useGetOrderProductsCountQuery,
  useGetOrderProductsQuery,
  useConfirmOrderMutation,
  useConfirmOrderBankTransferMutation,
  useUpdateOrderMutation,
  useCancelProductMutation,
  useGetWebOrderIdQuery,
  useGetAffiliatedOrdersQuery,
  useGetAffiliatedOrdersCountQuery,
  useGetSingleAffiliatedOrderQuery,
  useCreateStripePaymentIntentMutation,
  useCreatePaypalOrderMutation,
} = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query<Order[], GetOrdersRequestParams>({
      query: (params) => ({
        url: 'orders',
        params,
      }),
    }),
    getOrdersCount: builder.query<CountResponse, string | undefined>({
      query: (searchQuery) => ({
        url: 'orders/count',
        params: { searchQuery },
      }),
    }),
    getSingleOrder: builder.query<Order, string>({
      query: (id) => ({
        url: `orders/${id}`,
      }),
      providesTags: ['order'],
    }),
    getOrderProductsCount: builder.query<
      CountResponse,
      OrderProductsCountApiQueryParams
    >({
      query: ({ id, searchQuery }) => ({
        url: `orders/${id}/products/count`,
        params: { searchQuery },
      }),
    }),
    getOrderProducts: builder.query<OrderProduct[], GetOrderProductRequest>({
      query: ({ id, params }) => ({
        url: `orders/${id}/products`,
        params,
      }),
      providesTags: ['orderProducts'],
    }),
    confirmOrder: builder.mutation<Order, ConfirmOrderRequest>({
      query: ({ id }) => ({
        url: `orders/${id}/confirm`,
        method: 'POST',
      }),
      invalidatesTags: ['order', 'orderProducts'],
    }),
    confirmOrderBankTransfer: builder.mutation<Order, ConfirmOrderRequest>({
      query: ({ id }) => ({
        url: `orders/${id}/confirm-bank-transfer`,
        method: 'POST',
      }),
      invalidatesTags: ['order', 'orderProducts'],
    }),
    updateOrder: builder.mutation<Order, UpdateOrderRequest>({
      query: ({ id, body }) => ({
        url: `orders/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['order'],
    }),
    cancelProduct: builder.mutation<OrderProduct, string>({
      query: (id) => ({
        url: `products/${id}/cancel`,
        method: 'POST',
      }),
      invalidatesTags: ['Products'],
    }),
    getWebOrderId: builder.query<GetWebOrderIdResponse, number>({
      query: (id) => ({
        url: `orders/web/${id}`,
      }),
    }),

    // Affiliated orders
    getAffiliatedOrders: builder.query<
      AffiliatedOrder[],
      GetOrdersRequestParams
    >({
      query: (params) => ({
        url: 'orders/affiliated',
        params,
      }),
    }),
    getAffiliatedOrdersCount: builder.query<CountResponse, string | undefined>({
      query: (searchQuery) => ({
        url: 'orders/affiliated/count',
        params: { searchQuery },
      }),
    }),
    getSingleAffiliatedOrder: builder.query<AffiliatedOrder, string>({
      query: (id) => ({
        url: `orders/affiliated/${id}`,
      }),
    }),
    createStripePaymentIntent: builder.mutation<
      StripePaymentIntent,
      { orderId: string }
    >({
      query: ({ orderId }) => ({
        url: `orders/${orderId}/stripe/payment-intent`,
        method: 'POST',
      }),
    }),
    createPaypalOrder: builder.mutation<PaypalOrder, { orderId: string }>({
      query: ({ orderId }) => ({
        url: `orders/${orderId}/paypal/orders`,
        method: 'POST',
      }),
    }),
  }),
});
