import { generateMockValue } from '../../utils/generateMockData';

export function generateGetClientProfileMock() {
  return generateMockValue({
    type: 'object',
    values: {
      userId: 'number',
      type: 'ClientType',
      vatTaxNumber: 'string',
      pec: 'string',
      sdi: 'string',
      province: 'string',
      city: 'string',
      zip: 'string',
      address: 'string',
      phone: 'string',
      sellerCode: {
        type: 'object',
        values: {
          code: 'string',
          seller: {
            type: 'object',
            values: { email: 'string', name: 'string' },
          },
        },
      },
    },
  });
}
