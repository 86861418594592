import { FC, Key, ReactNode, useState } from 'react';

import { SortOrder } from '@domain/types';
import {
  Group,
  Paper,
  rem,
  Skeleton,
  Table,
  Text,
  UnstyledButton,
} from '@mantine/core';
import {
  IconChevronDown,
  IconChevronUp,
  IconSelector,
} from '@tabler/icons-react';

import classes from './SortableTable.module.css';

interface ThProps {
  children: React.ReactNode;
  reversed?: boolean;
  sorted?: boolean;
  onSort?(): void;
}

const Th: FC<ThProps> = ({ children, reversed, sorted, onSort }) => {
  const SortingIcon = sorted
    ? reversed
      ? IconChevronUp
      : IconChevronDown
    : IconSelector;

  return (
    <Table.Th>
      {onSort ? (
        <UnstyledButton onClick={onSort}>
          <Group wrap="nowrap">
            {children}
            <SortingIcon size="1.1rem" />
          </Group>
        </UnstyledButton>
      ) : (
        <Text fw="bold">{children}</Text>
      )}
    </Table.Th>
  );
};
export interface TableData {
  key: Key;
  data: ReactNode[];
}

interface PaginationTableProps {
  data: TableData[];
  headings: Record<string, string | null>;
  sortableKeys?: string[];
  onSortingChange?: (key: string, order: SortOrder) => void;
  emptyText: string;
  loading?: boolean;
  footer?: TableData[];
  firstColumnSmall?: boolean;
  lastColumnRight?: boolean;
  minWidth?: string;
}

const SortableTable: React.FC<PaginationTableProps> = ({
  data,
  headings,
  sortableKeys = [],
  onSortingChange = null,
  emptyText = 'Nessun elemento presente',
  footer,
  firstColumnSmall = false,
  lastColumnRight = false,
  loading = false,
  minWidth = '70rem',
}) => {
  // ==========================================================================
  // General
  // ==========================================================================

  // ==========================================================================
  // State
  // ==========================================================================
  const [sortBy, setSortBy] = useState<string | null>(null);

  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  // ==========================================================================
  // Function
  // ==========================================================================
  const setSorting = (field: string) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    if (onSortingChange) {
      onSortingChange(field, reversed ? 'desc' : 'asc');
    }
  };

  const headingsElements = Object.entries(headings)
    .filter(([, title]) => title !== null)
    .map(([key, title]) => {
      if (sortableKeys.includes(key)) {
        return (
          <Th
            key={key}
            sorted={sortBy === key}
            reversed={reverseSortDirection}
            onSort={() => setSorting(key)}
          >
            {title}
          </Th>
        );
      } else {
        return <Th key={key}>{title}</Th>;
      }
    });

  const rows = data.map((row) => {
    const cells = Object.values(row.data).filter((cell) => cell !== null);

    return (
      <Table.Tr key={row.key} fz={rem('0.9rem')}>
        {cells.map((cell, i) => (
          <Table.Td
            key={i}
            style={{
              width: i === 0 && firstColumnSmall ? '0' : 'initial',
              ...(i === cells.length - 1 && lastColumnRight
                ? { display: 'flex', justifyContent: 'end' }
                : {}),
            }}
          >
            <Skeleton visible={loading}>{cell}</Skeleton>
          </Table.Td>
        ))}
      </Table.Tr>
    );
  });

  const rowsFooter = footer?.map((rowtotal) => (
    <Table.Tr key={rowtotal.key}>
      {Object.values(rowtotal.data).map((cell, i) => (
        <Table.Td key={i}>{cell}</Table.Td>
      ))}
    </Table.Tr>
  ));

  // ==========================================================================
  // Render
  // ==========================================================================

  return (
    <Paper style={{ overflow: 'hidden' }}>
      <Table.ScrollContainer minWidth={rem(minWidth)} type="scrollarea">
        <Table striped horizontalSpacing="md" className={classes.table}>
          <Table.Thead>
            <Table.Tr>{headingsElements}</Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {rows.length > 0 ? (
              rows
            ) : (
              <Table.Tr>
                <Table.Td colSpan={Object.keys(headings).length}>
                  <Text fw={500} ta="center">
                    {emptyText}
                  </Text>
                </Table.Td>
              </Table.Tr>
            )}
          </Table.Tbody>
          {rowsFooter && (
            <Table.Tfoot
            // style={{
            //   borderTop: '1px solid #222222',
            //   borderBottomLeftRadius: '8px',
            //   borderBottomRightRadius: '8px',
            //   overflow: 'hidden',
            // }}
            >
              {rowsFooter}
            </Table.Tfoot>
          )}
        </Table>
      </Table.ScrollContainer>
    </Paper>
  );
};

export default SortableTable;
