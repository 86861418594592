import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { UserRole } from '@interfaces/auth.interface';

import useAuth from '@hooks/useAuth';

import PrivateRoute from './PrivateRoute';

interface RoleSpecificRouteProps {
  roles: UserRole[];
  element: ReactNode;
}

export default function RoleSpecificRoute({
  roles,
  element,
}: RoleSpecificRouteProps) {
  const { user } = useAuth();

  return user && !roles.includes(user.role) ? (
    <Navigate to="/" />
  ) : (
    <PrivateRoute element={element} />
  );
}
