import { ReactNode } from 'react';

import { Box, Stack, Text, Title } from '@mantine/core';

import env from '@config/env';

import logo from '@assets/images/logo.svg';

import classes from './GuestLayout.module.css';

interface GuestLayoutProps {
  size?: number;
  children: ReactNode;
}

export default function GuestLayout({
  size = 420,
  children,
}: GuestLayoutProps) {
  return (
    <Stack align="center" justify="space-between" py="xl" px="md" mih="100vh">
      <Stack align="center" mt="xl">
        <img src={logo} className={classes.logo} alt="Logo Tuoteam" />
        <Box miw={size} w="min-content">
          <Title ta="center" style={{ fontSize: '1.8rem' }} mt="md">
            Area riservata
          </Title>

          {children}
        </Box>
      </Stack>
      <Text size="xs" c="dimmed">
        v{env.APP_VERSION}
      </Text>
    </Stack>
  );
}
