import { Navigate, useNavigate, useParams } from 'react-router-dom';

import {
  Button,
  Container,
  LoadingOverlay,
  Paper,
  PasswordInput,
  Text,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';

import { isApiError } from '@api/index';
import {
  useCheckRegistrationTokenQuery,
  useCompleteRegistrationMutation,
} from '@api/users.api';

import useAuth from '@hooks/useAuth';
import useGRecaptcha from '@hooks/useGRecaptcha';

import logo from '@assets/images/logo.svg';

export default function CompleteRegistration() {
  // ==========================================================================
  // General
  // ==========================================================================
  const { token } = useParams();

  const { isAuthenticated } = useAuth();

  const navigate = useNavigate();

  const { getRecaptchaToken } = useGRecaptcha();

  // ==========================================================================
  // Api
  // ==========================================================================
  const { isError, isLoading: isLoadingCheckToken } =
    useCheckRegistrationTokenQuery(token!);

  const [completeRegistration, { isLoading }] =
    useCompleteRegistrationMutation();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    password: '',
    passwordConfirm: '',
  };

  const form = useForm({
    initialValues,
    validate: {
      passwordConfirm: (value, values) =>
        value !== values.password ? 'Le password non corrispondono.' : null,
    },
  });

  const onSubmit = async (values: typeof initialValues) => {
    try {
      const recaptchaToken = await getRecaptchaToken('CompleteRegistration');

      await completeRegistration({
        token: token!,
        body: values,
        recaptchaToken,
      }).unwrap();

      showNotification({
        title: 'Registrazione completata correttamente!',
        message: 'Ora puoi accedere utilizzando la tua nuova password.',
      });

      navigate('/');
    } catch (e) {
      if (isApiError(e)) {
        if (e.status === 400) {
          form.setErrors({ general: e.data.message, ...e.data.errors });
        } else if (e.status === 404) {
          showNotification({
            title: 'Url non valido!',
            message: 'Url per la registrazione non valido o scaduto.',
            color: 'red',
          });

          navigate('/');
        }
      } else {
        form.setErrors({
          general: 'Errore inatteso. Per favore riprova più tardi.',
        });
      }
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return isAuthenticated ? (
    <Navigate to="/ordini" replace={true} />
  ) : (
    <>
      <LoadingOverlay visible={isLoadingCheckToken} />
      <Container size={420} my={40}>
        <img
          src={logo}
          style={{ display: 'block', maxWidth: '380px', margin: '0 auto' }}
          alt="Logo Tuoteam"
        />
        <Title ta="center" style={{ fontSize: '1.8rem' }} mt="md">
          Area riservata
        </Title>
        <Paper p={30} mt={30}>
          {isError ? (
            <>
              <Text size="sm">
                Link per la registrazione non valido o scaduto.
              </Text>
            </>
          ) : (
            <form
              onSubmit={form.onSubmit((values) => {
                onSubmit(values);
              })}
            >
              <Title order={2} ta="center" mb="lg">
                Completa la registrazione
              </Title>
              <Text size="sm" mb="lg">
                Crea una password.
              </Text>
              <PasswordInput
                label="Password"
                placeholder="La tua password"
                required
                {...form.getInputProps('password')}
              />
              <PasswordInput
                mt="md"
                label="Ripeti password"
                placeholder="La tua password"
                required
                {...form.getInputProps('passwordConfirm')}
              />
              {form.errors.general && (
                <Text c="red" size="sm" mt="xl">
                  {form.errors.general}
                </Text>
              )}
              <Button type="submit" fullWidth mt="xl" loading={isLoading}>
                Conferma
              </Button>
            </form>
          )}
        </Paper>
      </Container>
    </>
  );
}
