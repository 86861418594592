import { useLayoutEffect, useState } from 'react';
import { useOutletContext, useParams, useSearchParams } from 'react-router-dom';

import { ORDER_STATUS_COLORS, ORDER_STATUS_STRINGS } from '@domain/orderStatus';
import {
  PRODUCT_STATUS_COLORS,
  PRODUCT_STATUS_STRINGS,
} from '@domain/productStatus';
import { SortOrder } from '@domain/types';
import { Badge, Group, Skeleton, Stepper, Text, Title } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { dateToDateString } from '@utils/date';
import { priceToDecimal } from '@utils/price';

import {
  generateGetOrdersProductsMock,
  generateGetSingleAffiliatedOrderMock,
} from '@api/mocks/orders.mock';
import {
  GetOrdersRequestParams,
  GetOrdersSortBy,
  useGetOrderProductsCountQuery,
  useGetOrderProductsQuery,
  useGetSingleAffiliatedOrderQuery,
} from '@api/orders.api';

import Card from '@components/card/Card';
import { LayoutContextType } from '@components/layout/Layout';
import PaginationRow from '@components/PaginationRow';
import SearchBar from '@components/SearchBar';
import SortableTable from '@components/sortableTable/SortableTable';

import NotFound from './NotFound';

const statusIndexMap = {
  inserted: 0,
  waitingConfirmation: 1,
  waitingPayment: 2,
  confirmed: 3,
  production: 4,
  closed: 5,
};

export default function AffiliatedOrderDetail() {
  // ==========================================================================
  // General
  // ==========================================================================
  const { id } = useParams();

  const [searchParams] = useSearchParams();

  // ==========================================================================
  // State
  // ==========================================================================
  const [filters, setFilters] = useState<GetOrdersRequestParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 5),
    searchQuery: searchParams.get('search') || undefined,
  });

  const [searchQuery] = useDebouncedValue(filters.searchQuery, 200, {
    leading: true,
  });

  // ==========================================================================
  // Api
  // ==========================================================================
  const {
    data: order = generateGetSingleAffiliatedOrderMock(),
    isLoading,
    error,
  } = useGetSingleAffiliatedOrderQuery(id!);

  // Get initial products count
  const {
    data: productsCount = { count: 0 },
    isLoading: isLoadingProductsCount,
  } = useGetOrderProductsCountQuery({ id: id!, searchQuery });

  const {
    data: productsData = generateGetOrdersProductsMock(),
    isLoading: isProductsLoading,
  } = useGetOrderProductsQuery({
    id: id!,
    params: { ...filters, searchQuery },
  });

  // Map data
  const products = productsData.map((product) => {
    const data = [
      <img
        src={product.imageUrl}
        alt={product.name}
        style={{ height: '2rem' }}
      />,
      product.sku,
      product.name,
      product.color || '-',
      product.size || '-',
      product.quantity,
      <Group gap={3} wrap="nowrap">
        <span>€</span>
        {product.salePercent && (
          <span>
            <span style={{ textDecoration: 'line-through' }}>
              {priceToDecimal(product.totalPrice)}
            </span>
          </span>
        )}
        <span>
          {priceToDecimal(
            product.totalPrice * (1 - (product.salePercent || 0) / 100),
          )}
        </span>
      </Group>,
      product.salePercent ? `${product.salePercent}%` : '-',
      product.shippingDate
        ? new Date(product.shippingDate).toLocaleDateString('IT-it')
        : '-',
      <Badge color={PRODUCT_STATUS_COLORS[product.status]}>
        {PRODUCT_STATUS_STRINGS[product.status]}
      </Badge>,
    ];

    return {
      key: product.id,
      data,
    };
  });

  // ==========================================================================
  // Render
  // ==========================================================================

  const totalPriceNoDiscount = order?.total || 0;

  const totalPrice =
    totalPriceNoDiscount *
    (order?.paymentType !== 'onlineOnConfirm' ? 0.97 : 1);

  const totalPages = Math.ceil(productsCount.count / filters.pageLength!);

  const stepperSteps = Object.keys(ORDER_STATUS_STRINGS)
    .filter((status) => status !== 'processingPayment')
    .map((status) => (
      <Stepper.Step
        key={status}
        label={
          ORDER_STATUS_STRINGS[status as keyof typeof ORDER_STATUS_STRINGS]
        }
        color={ORDER_STATUS_COLORS[status as keyof typeof ORDER_STATUS_STRINGS]}
      />
    ));

  const { setLayoutProps } = useOutletContext<LayoutContextType>();

  useLayoutEffect(() => {
    setLayoutProps({
      title: `Dettagli ordine #${id}`,
      backLink: { title: 'Lista ordini', to: '/ordini-affiliati' },
    });
  }, [setLayoutProps, id]);

  return !isLoading &&
    (!order || (error && (error as FetchBaseQueryError).status === 404)) ? (
    <NotFound />
  ) : (
    <>
      <>
        <Stepper
          visibleFrom="sm"
          my={40}
          active={
            statusIndexMap[
              order?.status
                ? order.status !== 'processingPayment'
                  ? order.status
                  : 'waitingPayment'
                : 'inserted'
            ]
          }
          size="sm"
          styles={{
            step: {
              flexDirection: 'column',
            },
            stepBody: {
              marginLeft: '0',
              marginTop: '7px',
            },
          }}
        >
          {stepperSteps}
        </Stepper>

        <Group grow align="start">
          <Card title="Informazioni ordine">
            <Group justify="space-between">
              <Text fw="bold">Numero ordine</Text>
              <Skeleton visible={isLoading}>{order.id}</Skeleton>
            </Group>
            <Group justify="space-between">
              <Text fw="bold">Riferimento cliente</Text>
              <Skeleton visible={isLoading}>
                {order.customerRef || '-'}
              </Skeleton>
            </Group>
            <Group justify="space-between">
              <Text fw="bold">Data</Text>
              <Skeleton visible={isLoading}>
                {dateToDateString(new Date(order.date))}
              </Skeleton>
            </Group>
          </Card>

          <Card
            title={`Indirizzo di ${order.shippingMethod === 'courier' ? 'consegna' : 'ritiro'}`}
          >
            {order.shippingMethod === 'courier' ? (
              <>
                <Skeleton visible={isLoading}>
                  {order.shippingZip} - {order.shippingCity} (
                  {order.shippingProvince}), {order.shippingNation}
                </Skeleton>
              </>
            ) : (
              <>
                <Skeleton visible={isLoading}>
                  Via dell'Artigianato, 41/A
                </Skeleton>
                <Skeleton visible={isLoading}>
                  36030 - Villaverla (VI), Italia
                </Skeleton>
              </>
            )}
          </Card>

          <Card title="Spedizione e pagamento">
            <Group justify="space-between">
              <Text fw="bold">Tipologia spedizione</Text>
              <Skeleton visible={isLoading}>
                {order.shippingMethod === 'courier'
                  ? 'Corriere'
                  : 'Ritiro in sede'}
              </Skeleton>
            </Group>

            {order.shippingMethod === 'courier' && (
              <>
                <Group justify="space-between">
                  <Text fw="bold">Data spedizione prevista</Text>
                  <Skeleton visible={isLoading}>
                    {order.shippingDate
                      ? dateToDateString(new Date(order!.shippingDate))
                      : 'Non disponibile'}
                  </Skeleton>
                </Group>
              </>
            )}
            <Group justify="space-between">
              <Text fw="bold">Tipologia pagamento</Text>
              <Skeleton visible={isLoading}>
                {order.paymentType === 'onlineOnConfirm' ? (
                  'Pagamento online'
                ) : (
                  <Group style={{ display: 'inline-flex' }} gap="xs">
                    Bonifico bancario
                  </Group>
                )}
              </Skeleton>
            </Group>
            <Group justify="space-between">
              <Text fw="bold">Totale (Iva In.)</Text>
              <Skeleton visible={isLoading} ta="right">
                {order.status === 'inserted' ||
                order.status === 'waitingConfirmation' ||
                order.status === 'waitingPayment' ? (
                  <>
                    € {priceToDecimal(totalPriceNoDiscount)}
                    <br />
                    {order.paymentType !== 'onlineOnConfirm' && (
                      <>(€ {priceToDecimal(totalPrice)} pagando online)</>
                    )}
                  </>
                ) : order.total !== totalPriceNoDiscount ? (
                  <>
                    <span style={{ textDecoration: 'line-through' }}>
                      € {priceToDecimal(totalPriceNoDiscount)}
                    </span>{' '}
                    {order.paymentType !== 'onlineOnConfirm' && (
                      <>€ {priceToDecimal(order.total)}</>
                    )}
                  </>
                ) : (
                  <>€ {priceToDecimal(totalPriceNoDiscount)}</>
                )}
              </Skeleton>
            </Group>
            <Group justify="space-between">
              <Text fw="bold">Provvigione</Text>
              <Skeleton visible={isLoading} ta="right">
                € {priceToDecimal(order.sellerCommission || 0)}
              </Skeleton>
            </Group>
          </Card>
        </Group>
        <Title order={3} mt="lg">
          Prodotti
        </Title>
        <Group mt="lg" mb="md">
          <SearchBar
            placeholder="Ricerca per codice o nome prodotto"
            value={filters.searchQuery}
            onChange={(newValue) =>
              setFilters({ ...filters, searchQuery: newValue })
            }
          />
        </Group>
        <SortableTable
          data={products}
          headings={{
            imageUrl: '',
            productSku: 'Codice',
            name: 'Nome',
            color: 'Colore',
            size: 'Taglia',
            quantity: 'Qta.',
            totalPrice: 'Prezzo tot.',
            salePercent: 'Sconto',
            shippingDate: 'Data sped. prev.',
            status: 'Stato',
          }}
          sortableKeys={['sku', 'name']}
          onSortingChange={(key: string, order: SortOrder) =>
            setFilters({
              ...filters,
              sortBy: key as GetOrdersSortBy,
              sortOrder: order,
            })
          }
          emptyText="Nessun prodotto trovato"
          loading={isProductsLoading || isLoadingProductsCount}
        />

        <PaginationRow
          page={filters.page!}
          pageLength={filters.pageLength!}
          totalPages={totalPages}
          onPageChange={(newPage) => setFilters({ ...filters, page: newPage })}
          onPageLengthChange={(newPageLength) =>
            setFilters({ ...filters, pageLength: newPageLength, page: 1 })
          }
        />
      </>
    </>
  );
}
