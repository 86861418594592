import {
  CountResponse,
  PaginationQueryParams,
  SortQueryParams,
} from '@interfaces/common.interface';
import {
  Notification,
  NotificationsFilter,
} from '@interfaces/notifications.interface';

import api from './';

export interface NotificationsApiQueryParams
  extends PaginationQueryParams,
    Partial<Omit<SortQueryParams<unknown>, 'sortBy'>> {
  filter?: NotificationsFilter;
}

export interface UpdateNotificationRequest {
  id: number;
  body: {
    read?: boolean;
    archived?: boolean;
  };
}

export const {
  useGetNotificationsCountQuery,
  useGetNotificationsQuery,
  useModifyNotificationMutation,
} = api.injectEndpoints({
  endpoints: (builder) => ({
    getNotificationsCount: builder.query<
      CountResponse,
      NotificationsFilter | undefined
    >({
      query: (filter) => ({
        url: 'notifications/count',
        params: { filter },
      }),
      providesTags: ['notifications'],
    }),
    getNotifications: builder.query<
      Notification[],
      NotificationsApiQueryParams
    >({
      query: (params) => ({
        url: '/notifications',
        params,
      }),
      providesTags: ['notifications'],
    }),
    modifyNotification: builder.mutation<
      Notification,
      UpdateNotificationRequest
    >({
      query: ({ id, body }) => ({
        url: `notifications/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['notifications'],
    }),
  }),
});
