import { useLayoutEffect } from 'react';

import env from '@config/env';

const useScript = (
  src: string,
  options: { defer?: boolean; productionOnly?: boolean } = {
    defer: true,
    productionOnly: false,
  },
) => {
  return useLayoutEffect(() => {
    if (!options.productionOnly! || env.MODE === 'production') {
      const script = document.createElement('script');
      script.src = src;
      script.defer = options.defer!;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [src, options]);
};

export default useScript;
