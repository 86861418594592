import { SortOrder } from '@domain/types';

import { CountResponse } from '@interfaces/common.interface';
import { Invoice } from '@interfaces/invoices.interface';

import api from './';

export type InvoicesSortBy = 'id' | 'date';

export interface GetInvoicesRequestParams {
  sortBy?: InvoicesSortBy;
  sortOrder?: SortOrder;
  searchQuery?: string;
  page?: number;
  pageLength?: number;
}

export const { useGetInvoicesQuery, useGetInvoicesCountQuery } =
  api.injectEndpoints({
    endpoints: (builder) => ({
      getInvoices: builder.query<Invoice[], GetInvoicesRequestParams>({
        query: (params) => ({
          url: 'invoices',
          params,
        }),
      }),
      getInvoicesCount: builder.query<CountResponse, string | undefined>({
        query: (searchQuery) => ({
          url: 'invoices/count',
          params: { searchQuery },
        }),
      }),
    }),
  });
