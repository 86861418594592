import {
  RequestUserActivationRequest,
  User,
  UserPatchRequest,
  UserRole,
} from '@interfaces/auth.interface';
import {
  CountResponse,
  PaginationQueryParams,
  SearchQueryParams,
  SortQueryParams,
} from '@interfaces/common.interface';

import api from './';

export type GetUsersSortBy = 'name' | 'email';

export interface CountUsersRequestParams extends Partial<SearchQueryParams> {
  role?: UserRole;
}

export interface CreatePasswordlessUserRequestBody {
  email: string;
  name: string;
  role: 'admin' | 'seller';
}

export interface GetUsersRequestParams
  extends CountUsersRequestParams,
    Partial<PaginationQueryParams>,
    Partial<SortQueryParams<GetUsersSortBy>> {}

export interface ActivateUserRequest {
  token: string;
  body: {
    password?: string;
  };
}

interface CheckTokenResponse {
  message: string;
}

interface CompleteRegistrationRequest {
  token: string;
  body: { password: string };
  recaptchaToken: string;
}

interface CompleteRegistrationResponse {
  message: string;
}

export interface UserModifyParams {
  name?: string;
  oldPassword?: string;
  password?: string;
  phone?: string;
  vatTaxNumber?: string;
  pec?: string | null;
  sdi?: string | null;
  address?: string;
  city?: string;
  zip?: string;
  province?: string;
  fcmToken?: string;
  pushNotificationsEnabled?: boolean;
  emailNotificationsEnabled?: boolean;
}

export interface UserModifyResponse {
  name: string;
  phone: string;
  pec: string | null;
  sdi: string | null;
  billingAddress: {
    id: number;
    address: string;
    city: string;
    zip: string;
    province: string;
    nation: string;
  };
  user: {
    id: number;
    email: string;
  };
}

export const {
  useRequestActivationMutation,
  useActivateMutation,
  usePatchCurrentUserMutation,
  useCreatePasswordlessUserMutation,
  useGetSingleUserQuery,
  useVerifyActivationTokenQuery,
  useActivateUserMutation,
  useDeactivateUserMutation,
  useDeleteUserMutation,
  useCountUsersQuery,
  useGetUsersQuery,
  useCheckRegistrationTokenQuery,
  useCompleteRegistrationMutation,
} = api.injectEndpoints({
  endpoints: (build) => ({
    patchCurrentUser: build.mutation<User, UserPatchRequest>({
      query: (body) => ({
        url: 'users',
        method: 'PATCH',
        body,
      }),
    }),
    createPasswordlessUser: build.mutation<
      User,
      CreatePasswordlessUserRequestBody
    >({
      query: (body) => ({
        url: 'users',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['users'],
    }),
    getSingleUser: build.query<User, number>({
      query: (id) => ({
        url: `users/${id}`,
      }),
      providesTags: ['users'],
    }),
    requestActivation: build.mutation<void, RequestUserActivationRequest>({
      query: (body) => ({
        url: `users/activate/request`,
        method: 'POST',
        body,
      }),
    }),
    verifyActivationToken: build.query<{ requiresPassword: boolean }, string>({
      query: (token) => ({
        url: `users/activate/${token}`,
        method: 'GET',
      }),
    }),
    activate: build.mutation<void, ActivateUserRequest>({
      query: ({ token, body }) => ({
        url: `users/activate/${token}`,
        method: 'POST',
        body,
      }),
    }),
    activateUser: build.mutation<User, number>({
      query: (id) => ({
        url: `users/${id}/activate`,
        method: 'POST',
      }),
      invalidatesTags: ['users'],
    }),
    deactivateUser: build.mutation<User, number>({
      query: (id) => ({
        url: `users/${id}/deactivate`,
        method: 'POST',
      }),
      invalidatesTags: ['users'],
    }),
    deleteUser: build.mutation<User, number>({
      query: (id) => ({
        url: `users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['users'],
    }),
    countUsers: build.query<CountResponse, CountUsersRequestParams>({
      query: (params) => ({
        url: 'users/count',
        params,
      }),
      providesTags: ['users'],
    }),
    getUsers: build.query<User[], GetUsersRequestParams>({
      query: (params) => ({
        url: 'users',
        params,
      }),
      providesTags: ['users'],
    }),
    checkRegistrationToken: build.query<CheckTokenResponse, string>({
      query: (token) => ({
        url: `/users/complete-registration/${token}`,
      }),
    }),
    completeRegistration: build.mutation<
      CompleteRegistrationResponse,
      CompleteRegistrationRequest
    >({
      query: ({ token, body, recaptchaToken }) => ({
        url: `/users/complete-registration/${token}`,
        method: 'POST',
        body,
        headers: {
          'x-grecaptcha': recaptchaToken,
        },
      }),
    }),
  }),
});
