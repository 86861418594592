import { useSearchParams } from 'react-router-dom';

import { Select, SelectProps } from '@mantine/core';

interface QSSelectProps extends SelectProps {
  qsKey: string;
}

export default function QSSelect({ qsKey, ...rest }: QSSelectProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Select
      {...rest}
      onChange={(value, option) => {
        if (value) {
          setSearchParams({ ...searchParams, role: value });
        } else {
          searchParams.delete(qsKey);
          setSearchParams(searchParams);
        }

        rest.onChange?.(value, option);
      }}
    />
  );
}
