import { useLayoutEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

import { LayoutContextType } from '@components/layout/Layout';

export default function AdminAccount() {
  // ==========================================================================
  // General
  // ==========================================================================

  // ==========================================================================
  // State
  // ==========================================================================

  // ==========================================================================
  // Api
  // ==========================================================================

  // ==========================================================================
  // Form
  // ==========================================================================

  // ==========================================================================
  // Handlers
  // ==========================================================================

  // ==========================================================================
  // Render
  // ==========================================================================
  const { setLayoutProps } = useOutletContext<LayoutContextType>();

  useLayoutEffect(() => {
    setLayoutProps({
      title: 'Account',
    });
  }, [setLayoutProps]);

  return <></>;
}
