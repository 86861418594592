import { ForwardRefExoticComponent, RefAttributes } from 'react';

import {
  Icon,
  IconDiscount2,
  IconFileBarcode,
  IconFileEuro,
  IconFileInvoice,
  IconListDetails,
  IconPackage,
  IconProps,
  IconReceipt,
  IconUsers,
} from '@tabler/icons-react';

import { UserRole } from '@interfaces/auth.interface';

export const linksData: {
  label: string;
  icon: ForwardRefExoticComponent<Omit<IconProps, 'ref'> & RefAttributes<Icon>>;
  link: string;
  roles?: UserRole[];
}[] = [
  {
    label: 'Ordini utenti affiliati',
    icon: IconListDetails,
    link: '/ordini-affiliati',
    roles: ['seller'],
  },
  {
    label: 'Ordini',
    icon: IconListDetails,
    link: '/ordini',
    roles: ['client'],
  },
  {
    label: 'Offerte',
    icon: IconFileInvoice,
    link: '/offerte',
    roles: ['client'],
  },
  {
    label: 'Fatture',
    icon: IconFileEuro,
    link: '/documenti/fatture',
    roles: ['client'],
  },
  {
    label: 'Scontrini',
    icon: IconReceipt,
    link: '/documenti/scontrini',
    roles: ['client'],
  },
  {
    label: 'Ddt',
    icon: IconFileBarcode,
    link: '/documenti/ddt',
    roles: ['client'],
  },
  {
    label: 'Utenti',
    icon: IconUsers,
    link: '/utenti',
    roles: ['admin'],
  },
  {
    label: 'Codici sconto',
    icon: IconDiscount2,
    link: '/coupons',
    roles: ['admin'],
  },
  {
    label: 'Costi spedizione',
    icon: IconPackage,
    link: '/costi-spedizione',
    roles: ['admin'],
  },
];
