import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import env from '@config/env';

export interface Provincia {
  sigla: string;
  nome: string;
}

export interface Comune {
  codice: string;
  nome: string;
  cap: string;
}

const apiComuni = createApi({
  reducerPath: 'apiComuni',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api-comuni.miroirstudio.com',
    headers: {
      'x-api-key': env.APICOMUNI_API_KEY,
    },
  }),
  endpoints: (builder) => ({
    getProvinces: builder.query<Provincia[], void>({
      query: () => ({
        url: '/province',
      }),
    }),
    getCities: builder.query<Comune[], string>({
      query: (provinceCode) => ({
        url: '/comuni',
        params: { siglaProvincia: provinceCode },
      }),
    }),
  }),
});

export default apiComuni;

export const { useGetProvincesQuery, useGetCitiesQuery } = apiComuni;
