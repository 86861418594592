import { Category } from '@interfaces/categories.interface';

import api from './';

export const { useGetAllCategoriesQuery } = api.injectEndpoints({
  endpoints: (build) => ({
    getAllCategories: build.query<Category[], void>({
      query: () => ({
        url: 'categories',
      }),
    }),
  }),
});
