import { ReactNode } from 'react';

import { Group, Skeleton, Text, Title, UnstyledButton } from '@mantine/core';
import { IconBell } from '@tabler/icons-react';
import { dateToDateString, dateToHourString } from '@utils/date';

import classes from './NotificationCard.module.css';

interface NotificationCardProps {
  title: string;
  description: string;
  read: boolean;
  date: Date;
  onClick: () => void;
  icon?: ReactNode;
  loading?: boolean;
}

export default function NotificationCard({
  title,
  description,
  read,
  date,
  onClick,
  icon = <IconBell />,
  loading = false,
}: NotificationCardProps) {
  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <UnstyledButton
      className={classes.notificationButton}
      p="md"
      onClick={onClick}
      style={{ opacity: read ? 0.5 : 1 }}
    >
      <Skeleton visible={loading} style={{ width: '100%' }}>
        <Group justify="space-between">
          <Group>
            <div className={classes.notificationIcon}>{icon}</div>
            <div>
              <Title order={5} style={{ fontWeight: read ? 'normal' : '' }}>
                {title}
              </Title>
              <Text size="sm">{description}</Text>
            </div>
          </Group>
          <div>
            <Text size="xs" ta="right">
              {dateToDateString(date)}
            </Text>
            <Text size="xs" ta="right">
              {dateToHourString(date)}
            </Text>
          </div>
        </Group>
      </Skeleton>
    </UnstyledButton>
  );
}
