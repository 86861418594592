import api from './';

export interface GraphicsDraft {
  id: string;
  graphicUrl: string;
  note: string | null;
  confirmationDate: string | null;
  draftProductsRelationId: string;
}

interface ModifyGraphicRequest {
  id: string;
  body: {
    note?: string;
  };
}

export const {
  useGetGraphicsDraftsQuery,
  useModifyGraphicMutation,
  useConfirmGraphicMutation,
} = api.injectEndpoints({
  endpoints: (builder) => ({
    getGraphicsDrafts: builder.query<GraphicsDraft[], string>({
      query: (id) => ({
        url: `/graphics-drafts/relation/${id}`,
      }),
      providesTags: ['GraphicsDrafts'],
    }),
    modifyGraphic: builder.mutation<GraphicsDraft, ModifyGraphicRequest>({
      query: ({ id, body }) => ({
        method: 'PATCH',
        url: `/graphics-drafts/${id}`,
        body,
      }),
      invalidatesTags: ['GraphicsDrafts'],
    }),
    confirmGraphic: builder.mutation<GraphicsDraft, string>({
      query: (id) => ({
        method: 'POST',
        url: `/graphics-drafts/${id}/confirm`,
      }),
      invalidatesTags: ['GraphicsDrafts'],
    }),
  }),
});
