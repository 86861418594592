import { Button, Group, PasswordInput, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { closeAllModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { handleSubmitError } from '@utils/forms';

import useAuth from '@hooks/useAuth';

export default function EditUserPasswordForm() {
  // ==========================================================================
  // General
  // ==========================================================================
  const { patch, isLoading } = useAuth();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    oldPassword: '',
    password: '',
    passwordConfirm: '',
  };

  const form = useForm({
    initialValues,
    validate: {
      passwordConfirm: (value, values) =>
        value !== values.password ? 'Le password non corrispondono.' : null,
    },
  });

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await patch(values);

      showNotification({
        title: 'Password modificata',
        message: 'La password del tuo account è stata modificata con successo',
      });

      closeAllModals();
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <PasswordInput
        label="Vecchia password"
        placeholder="La tua password attuale"
        required
        {...form.getInputProps('oldPassword')}
      />
      <PasswordInput
        mt="xs"
        label="Nuova password"
        placeholder="La tua nuova passsword"
        required
        {...form.getInputProps('password')}
      />
      <PasswordInput
        mt="xs"
        label="Ripeti password"
        placeholder="La tua nuova passsword"
        required
        {...form.getInputProps('passwordConfirm')}
      />
      {form.errors.general && (
        <Text c="red" size="sm" mt="xl">
          {form.errors.general}
        </Text>
      )}
      <Group mt="xl">
        <Button
          variant="default"
          onClick={() => closeAllModals()}
          loading={isLoading}
        >
          Annulla
        </Button>
        <Button type="submit" loading={isLoading}>
          Conferma
        </Button>
      </Group>
    </form>
  );
}
