import './styles/fonts.global.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import env from '@config/env.ts';

import App from './App.tsx';
import { store } from './store.ts';

if (env.MODE === 'production') {
  (async () => {
    const { H } = await import('highlight.run');

    H.init(env.HIGHLIGHT_PROJECT_ID, {
      serviceName: 'frontend-app',
      tracingOrigins: [env.BASE_URL, /^\//],
      disableSessionRecording: true, // TODO: enable this
      version: env.APP_VERSION,
      networkRecording: {
        enabled: true,
        recordHeadersAndBody: true,
        urlBlocklist: [
          // insert full or partial urls that you don't want to record here
          // Out of the box, Highlight will not record these URLs (they can be safely removed):
          'https://www.googleapis.com/identitytoolkit',
          'https://securetoken.googleapis.com',
        ],
      },
    });
  })();
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
);
