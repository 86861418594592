import { useLayoutEffect, useState } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';

import { Anchor, Box, Group } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { IconFileTypePdf } from '@tabler/icons-react';
import { dateToDateString } from '@utils/date';

import {
  DdtSortBy,
  GetDdtRequestParams,
  useGetDdtCountQuery,
  useGetDdtQuery,
} from '@api/ddt.api';
import { generateGetDdtMock } from '@api/mocks/ddt.mock';

import { LayoutContextType } from '@components/layout/Layout';
import PaginationRow from '@components/PaginationRow';
import SearchBar from '@components/SearchBar';
import SortableTable from '@components/sortableTable/SortableTable';

export default function Ddt() {
  // ==========================================================================
  // General
  // ==========================================================================
  const [searchParams] = useSearchParams();

  const selectedDdt = searchParams.get('sel');

  // ==========================================================================
  // State
  // ==========================================================================
  const [filters, setFilters] = useState<GetDdtRequestParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 5),
    searchQuery: searchParams.get('search') || undefined,
  });

  const [searchQuery] = useDebouncedValue(filters.searchQuery, 200, {
    leading: true,
  });
  // ==========================================================================
  // Api
  // ==========================================================================

  // Get initial orders count
  const { data: ddtCount = { count: 0 }, isLoading: isLoadingDdtCount } =
    useGetDdtCountQuery(searchQuery);

  const { data = generateGetDdtMock(), isLoading } = useGetDdtQuery({
    ...filters,
    searchQuery,
  });

  // Map data
  const ddt = data.map((ddt) => {
    const data = [
      <Box fw={(ddt.id === selectedDdt && 'bold') || undefined}>
        {dateToDateString(new Date(ddt.date))}
      </Box>,
      <Box fw={(ddt.id === selectedDdt && 'bold') || undefined}>{ddt.id}</Box>,
      <Anchor href={ddt.url} target="_blank" rel="noopener noreferrer nofollow">
        <IconFileTypePdf />
      </Anchor>,
    ];

    return {
      key: ddt.id,
      data,
    };
  });

  // ==========================================================================
  // Render
  // ==========================================================================
  const { setLayoutProps } = useOutletContext<LayoutContextType>();

  useLayoutEffect(() => {
    setLayoutProps({
      title: 'Documenti di trasporto',
    });
  }, [setLayoutProps]);

  const totalPages = Math.ceil(ddtCount.count / filters.pageLength!);

  return (
    <>
      <Group mb="lg">
        <SearchBar
          placeholder="Cerca numero DDT"
          value={filters.searchQuery}
          onChange={(newValue) =>
            setFilters({ ...filters, searchQuery: newValue })
          }
        />
      </Group>
      <SortableTable
        data={ddt}
        headings={{
          date: 'Data',
          id: 'Numero DDT',
          pdfLink: 'Pdf',
        }}
        sortableKeys={['id', 'date']}
        onSortingChange={(key, order) =>
          setFilters({ ...filters, sortBy: key as DdtSortBy, sortOrder: order })
        }
        emptyText="Nessun DDT trovato"
        loading={isLoading || isLoadingDdtCount}
        minWidth="25rem"
      />
      <PaginationRow
        page={filters.page!}
        pageLength={filters.pageLength!}
        totalPages={totalPages}
        onPageChange={(newPage) => setFilters({ ...filters, page: newPage })}
        onPageLengthChange={(newPageLength) =>
          setFilters({ ...filters, pageLength: newPageLength, page: 1 })
        }
      />
    </>
  );
}
