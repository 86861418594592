import { Anchor, Group, Text } from '@mantine/core';

import env from '@config/env';

interface FooterProps {
  innerRef?: React.LegacyRef<HTMLElement>;
}

export default function Footer({ innerRef }: FooterProps) {
  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <footer ref={innerRef}>
      <Group py={{ base: 'xl', md: 'sm' }} justify="space-between">
        <Group>
          <Text size="xs" c="dimmed">
            v{env.APP_VERSION}
          </Text>
          {/* <div
            style={{ textDecoration: 'none' }}
            dangerouslySetInnerHTML={{
              __html:
                '<a href="https://www.iubenda.com/privacy-policy/44070408" class="iubenda-black no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">Privacy Policy</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>',
            }}
          ></div>
          <div
            dangerouslySetInnerHTML={{
              __html:
                '<a href="https://www.iubenda.com/privacy-policy/44070408/cookie-policy" class="iubenda-black no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Cookie Policy ">Cookie Policy</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>',
            }}
          ></div> */}
        </Group>
        <Text size="xs">
          Developed by{' '}
          <Anchor
            size="xs"
            href="https://www.miroirstudio.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            MiroirStudio
          </Anchor>
        </Text>
      </Group>
    </footer>
  );
}
