import { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Anchor,
  Button,
  Container,
  PasswordInput,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { handleSubmitError } from '@utils/forms';

import { SigninRequestBody } from '@interfaces/auth.interface';

import { isApiError } from '@api/index';
import { useRequestActivationMutation } from '@api/users.api';

import useAuth from '@hooks/useAuth';
import useGRecaptcha from '@hooks/useGRecaptcha';

import FormsPaper from '@components/FormsPaper';

export default function LoginForm() {
  // ==========================================================================
  // General
  // ==========================================================================
  const { signin, isLoading } = useAuth();
  const { getRecaptchaToken } = useGRecaptcha();

  // ==========================================================================
  // State
  // ==========================================================================
  const [userNotConfirmed, setUserNotConfirmed] = useState(false);

  // ==========================================================================
  // Api
  // ==========================================================================
  const [requestActivation, { isLoading: isLoadingRequestActivation }] =
    useRequestActivationMutation();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    email: '',
    password: '',
  };

  const form = useForm({
    initialValues,
    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Email non valida'),
    },
  });

  const handleRequestActivation = async () => {
    try {
      await requestActivation({ email: form.values.email }).unwrap();

      setUserNotConfirmed(false);
      form.clearErrors();

      showNotification({
        title: 'Email inviata',
        message:
          'Nuova messaggio di attivazione inviato. Controlla la tua casella email.',
      });
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  const onSubmit = async (values: typeof initialValues) => {
    try {
      const data: SigninRequestBody = {
        email: values.email,
        password: values.password,
      };

      const recaptchaToken = await getRecaptchaToken('SignIn');

      await signin({ body: data, recaptchaToken });
    } catch (e) {
      if (isApiError(e)) {
        if (e.data && e.data.code === 'user-email-not-confirmed') {
          setUserNotConfirmed(true);
        }
      }

      handleSubmitError(e, form);
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <FormsPaper mt={30}>
      <form
        onSubmit={form.onSubmit((values) => {
          onSubmit(values);
        })}
      >
        <TextInput
          label="Email"
          placeholder="email@example.com"
          required
          {...form.getInputProps('email')}
        />
        <PasswordInput
          label="Password"
          placeholder="La tua password"
          required
          mt="md"
          {...form.getInputProps('password')}
        />
        {form.errors.general && (
          <Text c="red" size="sm" mt="xl">
            {form.errors.general}
          </Text>
        )}
        {userNotConfirmed && (
          <Button
            mt="sm"
            variant="default"
            fullWidth
            loading={isLoading || isLoadingRequestActivation}
            onClick={handleRequestActivation}
          >
            Invia nuova email di verifica
          </Button>
        )}

        <Button
          type="submit"
          fullWidth
          mt="xl"
          loading={isLoading || isLoadingRequestActivation}
        >
          Accedi
        </Button>
        <Container px="0" mt="sm">
          <Anchor to="/password-reset" component={Link} size="sm">
            Password dimenticata?
          </Anchor>
        </Container>
      </form>
    </FormsPaper>
  );
}
