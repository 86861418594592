import api from './';

interface PasswordResetRequestRequest {
  body: { email: string };
  recaptchaToken: string;
}

interface PasswordResetRequestResponse {
  message: string;
}

interface PasswordResetRequest {
  token: string;
  body: { password: string };
  recaptchaToken: string;
}

interface PasswordResetResponse {
  message: string;
}

interface CheckTokenResponse {
  message: string;
}

export const {
  usePasswordResetRequestMutation,
  usePasswordResetMutation,
  useCheckTokenQuery,
} = api.injectEndpoints({
  endpoints: (builder) => ({
    passwordResetRequest: builder.mutation<
      PasswordResetRequestResponse,
      PasswordResetRequestRequest
    >({
      query: ({ body, recaptchaToken }) => ({
        url: 'password-reset/request',
        method: 'POST',
        body,
        headers: {
          'x-grecaptcha': recaptchaToken,
        },
      }),
    }),
    checkToken: builder.query<CheckTokenResponse, string>({
      query: (token) => ({
        url: `password-reset/${token}`,
      }),
    }),
    passwordReset: builder.mutation<
      PasswordResetResponse,
      PasswordResetRequest
    >({
      query: ({ token, body, recaptchaToken }) => ({
        url: `password-reset/${token}`,
        method: 'POST',
        body,
        headers: {
          'x-grecaptcha': recaptchaToken,
        },
      }),
    }),
  }),
});
