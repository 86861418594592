import { useLayoutEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

import { ActionIcon, Button, Group, Switch } from '@mantine/core';
import { openConfirmModal, openModal } from '@mantine/modals';
import { IconPencil, IconPlus, IconTrash } from '@tabler/icons-react';
import { dateToDateHourString } from '@utils/date';

import {
  Coupon,
  useDeleteCouponMutation,
  useGetAllCouponsQuery,
  useUpdateCouponMutation,
} from '@api/coupons.api';

import UpsertCouponModal from '@components/CreateUpdateCouponModal';
import { LayoutContextType } from '@components/layout/Layout';
import SortableTable from '@components/sortableTable/SortableTable';

export default function Coupons() {
  // ==========================================================================
  // State
  // ==========================================================================

  // ==========================================================================
  // Api
  // ==========================================================================
  const { data = [], isLoading } = useGetAllCouponsQuery();
  const [updateCoupon, { isLoading: isLoadingUpdate }] =
    useUpdateCouponMutation();
  const [deleteCoupon, { isLoading: isLoadingDelete }] =
    useDeleteCouponMutation();

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handleCreateCouponClick = () => {
    openModal({
      title: 'Crea codice sconto',
      children: <UpsertCouponModal />,
    });
  };

  const handleUpdateCouponClick = (coupon: Coupon) => {
    openModal({
      title: 'Modifica codice sconto',
      children: <UpsertCouponModal coupon={coupon} />,
    });
  };

  const handleToggleCouponEnabled = (code: string, enable: boolean) => {
    openConfirmModal({
      title: `${enable ? 'Attiva' : 'Disattiva'} codice sconto`,
      children: enable
        ? 'Attivando il codice sconto gli utenti potranno nuovamente utilizzarlo. Proseguire?'
        : 'Disattivando il codice sconto gli utenti non potranno più utilizzarlo. Sarà possibile riattivarlo in qualsiasi momento. Proseguire?',
      labels: {
        cancel: 'Annulla',
        confirm: `${enable ? 'Attiva' : 'Disattiva'} codice sconto`,
      },
      confirmProps: { color: enable ? 'brand' : 'red' },
      onConfirm: () => updateCoupon({ code, body: { enabled: enable } }),
    });
  };

  const handleDeleteCouponClick = (code: string) => {
    openConfirmModal({
      title: 'Elimina codice sconto',
      children:
        'Eliminando il codice sconto gli utenti non potranno più utilizzarlo. Proseguire?',
      labels: {
        cancel: 'Annulla',
        confirm: 'Elimina codice sconto',
      },
      confirmProps: { color: 'red' },
      onConfirm: () => deleteCoupon(code),
    });
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  const { setLayoutProps } = useOutletContext<LayoutContextType>();

  useLayoutEffect(() => {
    setLayoutProps({ title: 'Codici sconto' });
  }, [setLayoutProps]);

  // Map data
  const coupons = data.map((coupon) => {
    const data = [
      <Switch
        checked={coupon.enabled}
        onChange={(e) =>
          handleToggleCouponEnabled(coupon.code, e.currentTarget.checked)
        }
        disabled={isLoadingUpdate}
      />,
      coupon.code,
      `${coupon.couponValueType === 'fixed' ? `€ ` : ''}${coupon.value.toString()}${coupon.couponValueType === 'percentage' ? '%' : ''}`,
      dateToDateHourString(new Date(coupon.startDate)),
      coupon.endDate ? dateToDateHourString(new Date(coupon.endDate)) : '-',
      coupon.limitPerUser?.toString() || 'Illimitato',
      coupon.categories.length > 0
        ? coupon.categories.map((c) => c.name).join(', ')
        : '-',
      coupon.usageCount.toString(),
      <Group>
        <ActionIcon
          onClick={() => handleUpdateCouponClick(coupon)}
          loading={isLoadingUpdate}
        >
          <IconPencil />
        </ActionIcon>
        <ActionIcon
          color="red"
          onClick={() => handleDeleteCouponClick(coupon.code)}
          loading={isLoadingDelete}
        >
          <IconTrash />
        </ActionIcon>
      </Group>,
    ];

    return {
      key: coupon.code,
      data,
    };
  });

  return (
    <>
      <Button
        mb="lg"
        onClick={handleCreateCouponClick}
        leftSection={<IconPlus />}
      >
        Crea codice sconto
      </Button>
      <SortableTable
        data={coupons}
        headings={{
          enabled: 'Attivo',
          code: 'Codice',
          value: 'Sconto',
          startDate: 'Data inizio',
          endDate: 'Data fine',
          limitPerUser: 'Limite per utente',
          categories: 'Categorie',
          usageCount: '# utilizzi',
          actions: '',
        }}
        emptyText="Nessun codice sconto trovato"
        loading={isLoading}
        lastColumnRight
      />
    </>
  );
}
