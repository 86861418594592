import { generateMockValue } from '../../utils/generateMockData';

export function generateGetAddressesMock() {
  return generateMockValue({
    type: 'array',
    minLength: 1,
    maxLength: 1,
    each: {
      type: 'object',
      values: {
        id: 'number',
        name: 'string',
        nation: 'string',
        province: 'string',
        city: 'string',
        zip: 'string',
        address: 'string',
        phone: 'string',
      },
    },
  });
}
