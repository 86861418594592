import { useCallback } from 'react';

import useScript from '@utils/scripts';

import env from '@config/env';

declare global {
  interface Window {
    grecaptcha: {
      ready: (f: () => void) => void;
      execute: (key: string, options: unknown) => Promise<string>;
    };
  }
}

export type RecaptchaActions =
  | 'SignUp'
  | 'SignIn'
  | 'PasswordResetRequest'
  | 'PasswordReset'
  | 'CompleteRegistration';

const scriptUrl = `https://www.google.com/recaptcha/api.js?render=${env.RECAPTCHA_PUBLIC_KEY}`;
const scriptOptions = { productionOnly: true };

const useGRecaptcha = () => {
  useScript(scriptUrl, scriptOptions);

  const getRecaptchaToken = useCallback(
    async (action: RecaptchaActions): Promise<string> => {
      if (env.MODE === 'production') {
        return await new Promise((res, rej) => {
          window.grecaptcha.ready(() => {
            window.grecaptcha
              .execute(env.RECAPTCHA_PUBLIC_KEY, { action })
              .then((token: string) => {
                res(token);
              })
              .catch((e: unknown) => rej(e));
          });
        });
      } else {
        return '';
      }
    },
    [],
  );

  return { getRecaptchaToken };
};

export default useGRecaptcha;
