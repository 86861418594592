import { useState } from 'react';

import { Alert } from '@mantine/core';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { IconAlertCircle } from '@tabler/icons-react';

import env from '@config/env';

import { Order } from '@interfaces/orders.interface';

import { useCreatePaypalOrderMutation } from '@api/orders.api';
import { useCapturePaypalOrderMutation } from '@api/paypal.api';

interface PaypalPaymentFormProps {
  order: Order;
}

export default function PaypalPaymentForm({ order }: PaypalPaymentFormProps) {
  // ==========================================================================
  // State
  // ==========================================================================
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  // ==========================================================================
  // Api
  // ==========================================================================
  const [createPaypalOrder] = useCreatePaypalOrderMutation();
  const [capturePaypalOrder] = useCapturePaypalOrderMutation();

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handleCreateOrder = async () => {
    try {
      setIsLoading(true);

      await createPaypalOrder({
        orderId: order.id,
      }).unwrap();

      setIsLoading(false);

      return order.id;
    } catch (error) {
      console.error(error);
      setMessage("Errore nella creazione dell'ordine. Riprovare più tardi.");

      setIsLoading(false);

      return '';
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleApproveOrder = async (data: any, actions: any) => {
    try {
      setIsLoading(true);

      const paypalOrder = await capturePaypalOrder(data.orderID).unwrap();

      // Three cases to handle:
      //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
      //   (2) Other non-recoverable errors -> Show a failure message
      //   (3) Successful transaction -> Show confirmation or thank you message

      const errorDetail = paypalOrder?.details?.[0];

      setIsLoading(false);

      if (errorDetail?.issue === 'INSTRUMENT_DECLINED') {
        // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
        // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
        return actions.restart();
      } else if (errorDetail) {
        // (2) Other non-recoverable errors -> Show a failure message
        throw new Error(`${errorDetail.description} (${paypalOrder.debug_id})`);
      } else {
        // (3) Successful transaction -> Show confirmation or thank you message
        // Or go to another URL:  actions.redirect('thank_you.html');

        actions.redirect(`${env.BASE_URL}/ordini/${order.id}`);
      }
    } catch (error) {
      console.error(error);
      setMessage(`Errore nel pagamento. Riprova più tardi.`);

      setIsLoading(false);
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <>
      <PayPalScriptProvider
        options={{
          clientId: env.PAYPAL_CLIENT_ID,
          'disable-funding': 'paylater,venmo,card,mybank',
          currency: 'EUR',
        }}
      >
        <PayPalButtons
          disabled={isLoading}
          createOrder={handleCreateOrder}
          onApprove={handleApproveOrder}
        />
      </PayPalScriptProvider>
      {message && (
        <Alert
          mt="lg"
          variant="filled"
          color="red"
          title="Errore pagamento"
          icon={<IconAlertCircle />}
        >
          {message}
        </Alert>
      )}
    </>
  );
}
