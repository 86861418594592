import { SortOrder } from '@domain/types';

import { CountResponse } from '@interfaces/common.interface';
import {
  Offer,
  OfferProduct,
  OfferWithTotal,
} from '@interfaces/offers.interface';

import api from './';

export type OffersSortBy = 'id' | 'date';

export type OffersProductsSortBy = 'sku' | 'name';

export interface GetOffersQueryParams {
  sortBy?: OffersSortBy;
  sortOrder?: SortOrder;
  page?: number;
  pageLength?: number;
  searchQuery?: string;
}

export interface GetOfferProductQueryParams {
  sortBy?: OffersProductsSortBy;
  sortOrder?: SortOrder;
  page?: number;
  pageLength?: number;
  searchQuery?: string;
}

export interface GetOfferProductRequest {
  id: string;
  params: GetOfferProductQueryParams;
}

export interface OfferProductsCountApiQueryParams {
  id: string;
  searchQuery?: string;
}

interface ConfirmOfferRequest {
  id: string;
}

export const {
  useGetOffersQuery,
  useGetOffersCountQuery,
  useGetSingleOfferQuery,
  useGetOfferProductsCountQuery,
  useGetOfferProductsQuery,
  useConfirmOfferMutation,
} = api.injectEndpoints({
  endpoints: (builder) => ({
    getOffers: builder.query<Offer[], GetOffersQueryParams>({
      query: (params) => ({
        url: 'offers',
        params,
      }),
    }),
    getOffersCount: builder.query<CountResponse, string | undefined>({
      query: (searchQuery) => ({
        url: 'offers/count',
        params: { searchQuery },
      }),
    }),
    getSingleOffer: builder.query<OfferWithTotal, string>({
      query: (id) => ({
        url: `offers/${id}`,
      }),
      providesTags: ['offer'],
    }),
    getOfferProductsCount: builder.query<
      CountResponse,
      OfferProductsCountApiQueryParams
    >({
      query: ({ id, searchQuery }) => ({
        url: `offers/${id}/products/count`,
        params: { searchQuery },
      }),
    }),
    getOfferProducts: builder.query<OfferProduct[], GetOfferProductRequest>({
      query: ({ id, params }) => ({
        url: `offers/${id}/products`,
        params,
      }),
    }),
    confirmOffer: builder.mutation<Offer, ConfirmOfferRequest>({
      query: ({ id }) => ({
        url: `offers/${id}/confirm`,
        method: 'POST',
      }),
      invalidatesTags: ['offer'],
    }),
  }),
});
