import { Category } from '@interfaces/categories.interface';

import api from './';

export type CouponValueType = 'fixed' | 'percentage';

export interface Coupon {
  code: string;
  startDate: string;
  endDate: string | null;
  value: number;
  couponValueType: CouponValueType;
  limitPerUser: number | null;
  categories: Category[];
  enabled: boolean;
  usageCount: number;
  createdAt: string;
  updatedAt: string;
}

export interface CreateCouponRequestBody {
  code: string;
  startDate: Date;
  endDate?: Date | null;
  value: number;
  couponValueType: CouponValueType;
  limitPerUser: number | null;
  categoriesIds: string[];
}

export interface UpdateCouponRequest {
  code: string;
  body: {
    startDate?: Date;
    endDate?: Date | null;
    value?: number;
    couponValueType?: CouponValueType;
    limitPerUser?: number | null;
    categoriesIds?: string[];
    enabled?: boolean;
  };
}

export const {
  useGetAllCouponsQuery,
  useCreateCouponMutation,
  useUpdateCouponMutation,
  useDeleteCouponMutation,
} = api.injectEndpoints({
  endpoints: (build) => ({
    getAllCoupons: build.query<Coupon[], void>({
      query: () => ({
        url: 'coupons',
      }),
      providesTags: ['coupons'],
    }),
    createCoupon: build.mutation<Coupon, CreateCouponRequestBody>({
      query: (body) => ({
        url: 'coupons',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['coupons'],
    }),
    updateCoupon: build.mutation<Coupon, UpdateCouponRequest>({
      query: ({ code, body }) => ({
        url: `coupons/${code}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['coupons'],
    }),
    deleteCoupon: build.mutation<Coupon, string>({
      query: (code) => ({
        url: `coupons/${code}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['coupons'],
    }),
  }),
});
