import { Button, Group, Select, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { closeAllModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { handleSubmitError } from '@utils/forms';

import { ClientProfile } from '@interfaces/clientsProfiles.interface';

import { useUpdateClientProfileMutation } from '@api/clientsProfiles.api';
import { useGetCitiesQuery, useGetProvincesQuery } from '@api/locations.api';

interface EditAddressFormProps {
  clientProfile: ClientProfile;
}

export default function EditClientProfileAddressForm({
  clientProfile,
}: EditAddressFormProps) {
  const initialValues = {
    address: clientProfile.address,
    city: clientProfile.city,
    zip: clientProfile.zip,
    province: clientProfile.province,
  };

  const form = useForm({
    initialValues,
  });

  const { data: provinces = [], isLoading: isLoadingProvinces } =
    useGetProvincesQuery();

  const { data: cities = [], isLoading: isLoadingCities } = useGetCitiesQuery(
    form.values.province,
    { skip: form.values.province === '' },
  );

  const [updateClientProfile, { isLoading }] = useUpdateClientProfileMutation();

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await updateClientProfile(values).unwrap();

      closeAllModals();

      showNotification({
        title: 'Indirizzo modificato',
        message: "L'indirizzo di fatturazione è stato modificato con successo",
      });
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <Select
        label="Provincia"
        placeholder="Seleziona provincia"
        searchable
        nothingFoundMessage="Nessun risultato"
        required
        disabled={isLoadingProvinces}
        data={provinces.map((province) => ({
          value: province.sigla,
          label: province.sigla,
        }))}
        {...form.getInputProps('province')}
        onChange={(e) => {
          form.setFieldValue('city', '');
          form.setFieldValue('zip', '');
          form.getInputProps('province').onChange(e);
        }}
      />
      <Select
        mt="xs"
        label="Città"
        placeholder="Seleziona città"
        searchable
        nothingFoundMessage="Nessun risultato"
        required
        disabled={form.values.province === '' || isLoadingCities}
        data={cities.map((city) => ({
          value: city.nome,
          label: city.nome,
        }))}
        {...form.getInputProps('city')}
        onChange={(e) => {
          form.setFieldValue('zip', '');
          form.getInputProps('city').onChange(e);
        }}
      />
      <Select
        mt="xs"
        label="CAP"
        placeholder="Seleziona CAP"
        searchable
        nothingFoundMessage="Nessun risultato"
        required
        disabled={form.values.city === '' || isLoadingCities}
        data={[
          {
            value: cities.find((c) => c.nome === form.values.city)?.cap || '',
            label: cities.find((c) => c.nome === form.values.city)?.cap || '',
          },
        ]}
        {...form.getInputProps('zip')}
      />
      <TextInput
        mt="md"
        label="Indirizzo"
        placeholder="Via Principale, 20"
        required
        {...form.getInputProps('address')}
      />
      {form.errors.general && (
        <Text c="red" size="sm" mt="xl">
          {form.errors.general}
        </Text>
      )}
      <Group mt="xl">
        <Button
          variant="default"
          onClick={() => closeAllModals()}
          loading={isLoading}
        >
          Annulla
        </Button>
        <Button type="submit" loading={isLoading}>
          Conferma
        </Button>
      </Group>
    </form>
  );
}
