import { PaypalOrder } from '@interfaces/orders.interface';

import api from './';

export const { useCapturePaypalOrderMutation } = api.injectEndpoints({
  endpoints: (build) => ({
    capturePaypalOrder: build.mutation<PaypalOrder, string>({
      query: (id) => ({
        url: `paypal/orders/${id}/capture`,
        method: 'POST',
      }),
    }),
  }),
});
