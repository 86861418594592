import { Button, Group, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { closeAllModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { handleSubmitError } from '@utils/forms';

import { useAddSellerCodeConnectionMutation } from '@api/clientsProfiles.api';

export default function AddSellerCodeConnectionForm() {
  const initialValues = {
    code: '',
  };

  const form = useForm({
    initialValues,
  });

  const [addSellerCodeConnection, { isLoading }] =
    useAddSellerCodeConnectionMutation();

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await addSellerCodeConnection(values.code).unwrap();

      closeAllModals();

      showNotification({
        title: 'Società aggiunta',
        message: 'Società affiliata aggiunta con successo',
      });
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <TextInput
        label="Codice Società"
        placeholder="Codice Società"
        required
        {...form.getInputProps('code')}
      />

      {form.errors.general && (
        <Text c="red" size="sm" mt="xl">
          {form.errors.general}
        </Text>
      )}
      <Group mt="xl">
        <Button
          variant="default"
          onClick={() => closeAllModals()}
          loading={isLoading}
        >
          Annulla
        </Button>
        <Button type="submit" loading={isLoading}>
          Conferma
        </Button>
      </Group>
    </form>
  );
}
