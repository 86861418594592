import { Link, Navigate, useSearchParams } from 'react-router-dom';

import { Anchor, Text } from '@mantine/core';

import useAuth from '@hooks/useAuth';

import LoginForm from '@components/LoginForm';

export default function Login() {
  // ==========================================================================
  // General
  // ==========================================================================
  const [searchParams] = useSearchParams();
  const { isAuthenticated, user } = useAuth();

  // ==========================================================================
  // Render
  // ==========================================================================
  return isAuthenticated ? (
    <Navigate
      to={
        searchParams.get('r') ||
        (user?.role === 'client'
          ? '/ordini'
          : user?.role === 'seller'
            ? '/ordini-affiliati'
            : '/utenti')
      }
      replace={true}
    />
  ) : (
    <>
      <Text ta="center">
        Non hai ancora un account?{' '}
        <Anchor to="/registrati" component={Link}>
          Crea account
        </Anchor>
      </Text>
      <LoginForm />
    </>
  );
}
