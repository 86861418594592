import { SigninRequest, SignupRequest, User } from '@interfaces/auth.interface';

import api from './';

export const {
  useGetCurrentUserQuery,
  useSigninMutation,
  useSignupMutation,
  useLogoutMutation,
} = api.injectEndpoints({
  endpoints: (build) => ({
    getCurrentUser: build.query<User, void>({
      query: () => ({ url: 'auth/me' }),
    }),
    signin: build.mutation<User, SigninRequest>({
      query: ({ body, recaptchaToken }) => ({
        url: 'auth/signin',
        method: 'POST',
        body,
        headers: {
          'x-grecaptcha': recaptchaToken,
        },
      }),
    }),
    signup: build.mutation<void, SignupRequest>({
      query: ({ body, recaptchaToken }) => ({
        url: 'auth/signup',
        method: 'POST',
        body,
        headers: {
          'x-grecaptcha': recaptchaToken,
        },
      }),
    }),
    logout: build.mutation<void, void>({
      query: (body) => ({
        url: 'auth/logout',
        method: 'POST',
        body,
      }),
    }),
  }),
});
