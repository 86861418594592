import useAuth from '@hooks/useAuth';

import AdminAccount from './AdminAccount';
import ClientAccount from './ClientAccount';

export default function Account() {
  const { user } = useAuth();

  return user!.role === 'admin' ? <AdminAccount /> : <ClientAccount />;
}
