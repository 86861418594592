import { useRef, useState } from 'react';

import {
  Accordion,
  ActionIcon,
  Alert,
  Anchor,
  Box,
  Button,
  CopyButton,
  Group,
  rem,
  Text,
  Tooltip,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import {
  IconAlertCircle,
  IconAlertTriangle,
  IconCheck,
  IconCircle,
  IconCopy,
  IconX,
} from '@tabler/icons-react';

import { Order, PaymentType } from '@interfaces/orders.interface';

import { useConfirmOrderBankTransferMutation } from '@api/orders.api';

import PaypalPaymentForm from '@components/PaypalPaymentForm';
import StripePaymentForm, {
  StripePaymentFormRef,
} from '@components/StripePaymentForm';

import classes from './OrderPaymentSection.module.css';

export interface OrderPaymentSectionProps {
  order: Order;
}

export default function OrderPaymentSection({
  order,
}: OrderPaymentSectionProps) {
  // ==========================================================================
  // General
  // ==========================================================================
  const stripeFormRef = useRef<StripePaymentFormRef>(null);

  // ==========================================================================
  // State
  // ==========================================================================
  const [paymentType, setPaymentType] = useState<PaymentType>();

  const [isLoading, setIsLoading] = useState(false);

  const [isStripeFormValid, setIsStripeFormValid] = useState(false);

  const [confirmOrderBankTransfer] = useConfirmOrderBankTransferMutation();

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handleSubmit = async () => {
    setIsLoading(true);

    if (paymentType === 'bankTransferOnConfirm') {
      try {
        await confirmOrderBankTransfer({ id: order.id }).unwrap();
        showNotification({
          title: 'Ordine confermato',
          message: 'Ordine confermato con successo',
        });
      } catch (e) {
        console.error(e);
        showNotification({
          title: 'Errore',
          message: "Errore nella conferma dell'ordine. Riprova",
          color: 'red',
          icon: <IconX />,
        });
      }
    } else if (paymentType === 'onlineStripe') {
      await stripeFormRef.current?.submitForm();
    }

    setIsLoading(false);
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <Alert title="Ordine in attesa di pagamento" icon={<IconAlertCircle />}>
      <Text>
        Il tuo ordine è in attesa di pagamento. Scegli un metodo di pagamento e
        prosegui per confermare l'ordine.
      </Text>

      <Box mt="lg" bg="#fff">
        <Accordion
          variant="contained"
          chevronPosition="left"
          disableChevronRotation
          chevron={<IconCircle />}
          classNames={{ item: classes.accordionItem }}
          onChange={(value) => {
            if (value) {
              setPaymentType(value as PaymentType);
            }
          }}
        >
          <Accordion.Item value="onlineStripe">
            <Accordion.Control
              disabled={paymentType === 'onlineStripe'}
              className={classes.accordionControl}
            >
              Carta di credito (Stripe){' '}
              {(order.paymentType === 'bankTransferOnConfirm' ||
                order.paymentType === 'other') && (
                <Text display="inline" fw="600">
                  (sconto del 3%)
                </Text>
              )}
            </Accordion.Control>
            <Accordion.Panel>
              {paymentType === 'onlineStripe' && (
                <StripePaymentForm
                  ref={stripeFormRef}
                  order={order}
                  onValidChange={setIsStripeFormValid}
                />
              )}
              {/* used to load component only when accordion is opened */}
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="onlinePaypal">
            <Accordion.Control
              disabled={paymentType === 'onlinePaypal'}
              className={classes.accordionControl}
            >
              Paypal{' '}
              {(order.paymentType === 'bankTransferOnConfirm' ||
                order.paymentType === 'other') && (
                <Text display="inline" fw="600">
                  (sconto del 3%)
                </Text>
              )}
            </Accordion.Control>
            <Accordion.Panel>
              {paymentType === 'onlinePaypal' && (
                <PaypalPaymentForm order={order} />
              )}
              {/* used to load component only when accordion is opened */}
            </Accordion.Panel>
          </Accordion.Item>
          {(order.paymentType === 'bankTransferOnConfirm' ||
            order.paymentType === 'other') && (
            <Accordion.Item value="bankTransferOnConfirm">
              <Accordion.Control
                disabled={paymentType === 'bankTransferOnConfirm'}
                className={classes.accordionControl}
              >
                Bonifico bancario
              </Accordion.Control>
              <Accordion.Panel>
                IBAN:{' '}
                <Group gap="xs">
                  <strong>IT28S0880760790000000033111</strong>
                  <CopyButton
                    value="IT28S0880760790000000033111"
                    timeout={2000}
                  >
                    {({ copied, copy }) => (
                      <Tooltip
                        label={copied ? 'Copiato' : 'Copia'}
                        withArrow
                        position="right"
                      >
                        <ActionIcon
                          color={copied ? 'teal' : 'gray'}
                          variant="subtle"
                          onClick={copy}
                        >
                          {copied ? (
                            <IconCheck style={{ width: rem(16) }} />
                          ) : (
                            <IconCopy style={{ width: rem(16) }} />
                          )}
                        </ActionIcon>
                      </Tooltip>
                    )}
                  </CopyButton>
                </Group>
                Per maggiori informazioni su come effettuare i bonifici consulta
                i{' '}
                <Anchor
                  size="sm"
                  href="https://www.tuoteam.com/termini"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Termini e condizioni
                </Anchor>
              </Accordion.Panel>
            </Accordion.Item>
          )}
        </Accordion>
      </Box>

      {!paymentType && (
        <Alert mt="md" mb="lg" variant="filled" color="yellow">
          Seleziona un metodo di pagamento per proseguire
        </Alert>
      )}
      {paymentType == 'onlinePaypal' ? (
        <Alert
          mt="md"
          variant="filled"
          color="yellow"
          title="Pagamento richiesto"
          icon={<IconAlertTriangle />}
        >
          Premi il pulsante "Paypal" e completa il pagamento per confermare
          l'ordine
        </Alert>
      ) : (
        <>
          {paymentType === 'onlineStripe' && !isStripeFormValid && (
            <Alert
              mt="md"
              variant="filled"
              color="yellow"
              title="Informazioni mancanti"
              icon={<IconAlertTriangle />}
            >
              Inserisci i dati corretti della carta di credito per proseguire
            </Alert>
          )}
          <Button
            size="md"
            mt="lg"
            disabled={
              !paymentType ||
              (paymentType === 'onlineStripe' && !isStripeFormValid)
            }
            loading={isLoading}
            onClick={handleSubmit}
            className={classes.nextStepButton}
          >
            Conferma ordine
            {paymentType === 'onlineStripe' && ' e paga'}
          </Button>
        </>
      )}
    </Alert>
  );
}
