import { useLayoutEffect, useState } from 'react';
import { Link, useOutletContext, useSearchParams } from 'react-router-dom';

import { ActionIcon, Group, Tooltip } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { IconInfoSquareRoundedFilled } from '@tabler/icons-react';
import { dateToDateString } from '@utils/date';

import { InvoicesSortBy } from '@api/invoices.api';
import { generateGetOffersMock } from '@api/mocks/offers.mock';
import {
  GetOffersQueryParams,
  useGetOffersCountQuery,
  useGetOffersQuery,
} from '@api/offers.api';

import { LayoutContextType } from '@components/layout/Layout';
import PaginationRow from '@components/PaginationRow';
import SearchBar from '@components/SearchBar';
import SortableTable from '@components/sortableTable/SortableTable';

export default function Offers() {
  const [searchParams] = useSearchParams();

  // ==========================================================================
  // State
  // ==========================================================================
  const [filters, setFilters] = useState<GetOffersQueryParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 5),
    searchQuery: searchParams.get('search') || undefined,
  });

  const [searchQuery] = useDebouncedValue(filters.searchQuery, 200, {
    leading: true,
  });

  // ==========================================================================
  // Api
  // ==========================================================================

  // Get initial orders count
  const { data: offersCount = { count: 0 }, isLoading: isLoadingOffersCount } =
    useGetOffersCountQuery(searchQuery);

  const { data = generateGetOffersMock(), isLoading } = useGetOffersQuery({
    ...filters,
    searchQuery,
  });

  // Map data
  const offers = data.map((offer) => {
    const data = [
      dateToDateString(new Date(offer.date)),
      offer.id,
      <Tooltip label="Dettagli offerta">
        <ActionIcon
          variant="subtle"
          component={Link}
          to={`/offerte/${offer.id}`}
        >
          <IconInfoSquareRoundedFilled />
        </ActionIcon>
      </Tooltip>,
    ];

    return {
      key: offer.id,
      data,
    };
  });

  // ==========================================================================
  // Render
  // ==========================================================================
  const { setLayoutProps } = useOutletContext<LayoutContextType>();

  useLayoutEffect(() => {
    setLayoutProps({
      title: 'Offerte',
    });
  }, [setLayoutProps]);

  const totalPages = Math.ceil(offersCount.count / filters.pageLength!);

  return (
    <>
      <Group mb="lg">
        <SearchBar
          placeholder="Cerca numero offerta"
          value={filters.searchQuery}
          onChange={(newValue) =>
            setFilters({ ...filters, searchQuery: newValue })
          }
        />
      </Group>
      <SortableTable
        data={offers}
        headings={{
          date: 'Data',
          id: 'Numero offerta',
          actions: '',
        }}
        sortableKeys={['id', 'date']}
        onSortingChange={(key, order) =>
          setFilters({
            ...filters,
            sortBy: key as InvoicesSortBy,
            sortOrder: order,
          })
        }
        emptyText="Nessun offerta trovata"
        loading={isLoading || isLoadingOffersCount}
        lastColumnRight
        minWidth="25rem"
      />
      <PaginationRow
        page={filters.page!}
        pageLength={filters.pageLength!}
        totalPages={totalPages}
        onPageChange={(newPage) => setFilters({ ...filters, page: newPage })}
        onPageLengthChange={(newPageLength) =>
          setFilters({ ...filters, pageLength: newPageLength, page: 1 })
        }
      />
    </>
  );
}
