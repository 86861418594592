import { Link } from 'react-router-dom';

import { Group, Menu, rem, Text } from '@mantine/core';
import { openModal } from '@mantine/modals';
import {
  IconKey,
  IconLogout,
  IconSettings,
  IconUserCircle,
} from '@tabler/icons-react';

import { useGetNotificationsCountQuery } from '@api/notifications.api';

import useAuth from '@hooks/useAuth';

import EditUserPasswordForm from '@components/EditUserPasswordForm';
import IconBadge from '@components/iconBadge/IconBadge';

import logo from '@assets/images/logo.svg';

import classes from './Header.module.css';

export default function Header() {
  const { user, logout } = useAuth();

  const { data: notificationsCount = { count: 0 } } =
    useGetNotificationsCountQuery('notRead');

  const handleEditPasswordClick = () => {
    openModal({
      title: 'Modifica password',
      children: <EditUserPasswordForm />,
    });
  };

  return (
    <header className={classes.header}>
      <Group justify="space-between">
        <a href="https://www.tuoteam.com" target="_blank">
          <img src={logo} alt="Logo Tuoteam" style={{ width: rem(200) }} />
        </a>
        <Group>
          <Menu withArrow>
            <Menu.Target>
              <IconUserCircle size="2rem" />
            </Menu.Target>
            <Menu.Dropdown>
              {user!.role !== 'admin' && (
                <Menu.Item component={Link} to="/account">
                  <Group>
                    <IconSettings size="1.5rem" />
                    <Text c="black" size="sm">
                      Account
                    </Text>
                  </Group>
                </Menu.Item>
              )}
              <Menu.Item onClick={handleEditPasswordClick}>
                <Group>
                  <IconKey size="1.5rem" />
                  <Text size="sm">Modifica password</Text>
                </Group>
              </Menu.Item>
              <Menu.Item onClick={logout} c="red">
                <Group>
                  <IconLogout size="1.5rem" />
                  <Text size="sm">Logout</Text>
                </Group>
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
          <IconBadge
            tooltipLabel="Notifiche"
            badgeText={
              notificationsCount.count !== 0
                ? notificationsCount.count.toString()
                : null
            }
            to="/notifiche"
          />
        </Group>
      </Group>
    </header>
  );
}
