import { useLayoutEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

import {
  ActionIcon,
  Button,
  Divider,
  Grid,
  Group,
  rem,
  Skeleton,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import { openConfirmModal, openModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconPencil, IconPlus, IconTrash, IconX } from '@tabler/icons-react';

import { Address } from '@interfaces/address.interface';

import {
  useDeleteAddressMutation,
  useGetAddressesQuery,
} from '@api/addresses.api';
import {
  useGetClientProfileQuery,
  useRemoveSellerCodeConnectionMutation,
} from '@api/clientsProfiles.api';
import { generateGetAddressesMock } from '@api/mocks/addresses.mock';
import { generateGetClientProfileMock } from '@api/mocks/clientsProfiles.mock';

import useAuth from '@hooks/useAuth';

import AddSellerCodeConnectionForm from '@components/AddSellerCodeConnectionForm';
import Card from '@components/card/Card';
import EditClientProfileAddressForm from '@components/EditClientProfileAddressForm';
import EditProfileInformationForm from '@components/EditProfileInformationForm';
import { LayoutContextType } from '@components/layout/Layout';
import UpsertAddressForm from '@components/UpsertAddressForm';

export default function ClientAccount() {
  // ==========================================================================
  // General
  // ==========================================================================
  const { user, isLoading: isLoadingAuth } = useAuth();

  // ==========================================================================
  // Api
  // ==========================================================================
  const {
    data: clientProfile = generateGetClientProfileMock(),
    isLoading: isLoadingClientProfile,
  } = useGetClientProfileQuery();
  // const { data: seller = generateGetSellerMock(), isLoading: isLoadingSeller } =
  //   useGetSellerQuery();

  const [
    removeSellerCodeConnection,
    { isLoading: isLoadingRemoveSellerCodeConnection },
  ] = useRemoveSellerCodeConnectionMutation();

  const {
    data: addresses = generateGetAddressesMock(),
    isLoading: isLoadingAddresses,
  } = useGetAddressesQuery();

  const [deleteAddress] = useDeleteAddressMutation();

  // ==========================================================================
  // Handlers
  // ==========================================================================

  const handleEditProfileInfoClick = () => {
    openModal({
      title: 'Modifica profilo',
      children: <EditProfileInformationForm clientProfile={clientProfile!} />,
    });
  };

  const handleEditProfileClick = () => {
    openModal({
      title: 'Modifica indirizzo di fatturazione',
      children: <EditClientProfileAddressForm clientProfile={clientProfile!} />,
    });
  };

  const handleAddSellerCodeConnectionClick = async () => {
    openModal({
      title: 'Aggiungi affiliazione',
      children: <AddSellerCodeConnectionForm />,
    });
  };

  const handleRemoveSellerCodeConnectionClick = async (code: string) => {
    openConfirmModal({
      title: 'Rimuovi affiliazione',
      children:
        'Rimuovendo la società affiliata non potrai più accedere ai prodotti riservati a quella società. Continuare?',
      labels: {
        cancel: 'Annulla',
        confirm: 'Rimuovi affiliazione',
      },
      confirmProps: { color: 'red' },
      onConfirm: async () => {
        try {
          await removeSellerCodeConnection(code).unwrap();

          showNotification({
            title: 'Affiliazione rimossa',
            message: 'Affiliazione rimossa con successo',
          });
        } catch (e) {
          showNotification({
            title: 'Errore',
            message: "Impossibile rimuovere l'affiliazione",
            color: 'red',
            icon: <IconX />,
          });
        }
      },
    });
  };

  const handleAddAddressClick = () => {
    openModal({
      title: 'Aggiungi indirizzo di spedizione',
      children: <UpsertAddressForm />,
    });
  };

  const handleEditAddressClick = (address: Address) => {
    openModal({
      title: 'Modifica indirizzo di spedizione',
      children: <UpsertAddressForm address={address} />,
    });
  };

  const handleDeleteAddressClick = async (id: number) => {
    openConfirmModal({
      title: 'Rimuovi indirizzo',
      children: "Stai rimuovendo l'indirizzo di spedizione. Continuare?",
      labels: {
        cancel: 'Annulla',
        confirm: 'Rimuovi indirizzo',
      },
      confirmProps: { color: 'red' },
      onConfirm: async () => {
        try {
          await deleteAddress(id).unwrap();

          showNotification({
            title: 'Indirizzo rimosso',
            message: 'Indirizzo rimosso con successo',
          });
        } catch (e) {
          showNotification({
            title: 'Errore',
            message: "Impossibile rimuovere l'indirizzo",
            color: 'red',
            icon: <IconX />,
          });
        }
      },
    });
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  const { setLayoutProps } = useOutletContext<LayoutContextType>();

  useLayoutEffect(() => {
    setLayoutProps({
      title: 'Account',
    });
  }, [setLayoutProps]);

  return (
    <>
      <Grid>
        {/* GRID ROW */}
        <Grid.Col span={{ lg: 6 }}>
          <Card
            title="Informazioni account"
            action={{
              title: 'Modifica',
              icon: <IconPencil />,
              onClick: handleEditProfileInfoClick,
            }}
          >
            <Group justify="space-between">
              <Text fw="bold">
                {clientProfile.type === 'company'
                  ? 'P. Iva / Codice fiscale'
                  : 'Codice fiscale'}
              </Text>
              <Skeleton visible={isLoadingClientProfile}>
                {clientProfile.vatTaxNumber}
              </Skeleton>
            </Group>
            <Group justify="space-between">
              <Text fw="bold">Email</Text>
              <Skeleton visible={isLoadingAuth}>{user!.email}</Skeleton>
            </Group>
            <Group justify="space-between">
              <Text fw="bold">Nome</Text>
              <Skeleton visible={isLoadingAuth}>{user!.name}</Skeleton>
            </Group>
            <Group justify="space-between">
              <Text fw="bold">Telefono</Text>
              <Skeleton visible={isLoadingClientProfile}>
                {clientProfile.phone}
              </Skeleton>
            </Group>

            {clientProfile.type === 'company' && (
              <>
                <Group justify="space-between">
                  <Text fw="bold">Pec</Text>
                  <Skeleton visible={isLoadingClientProfile}>
                    {clientProfile.pec || '-'}
                  </Skeleton>
                </Group>
                <Group justify="space-between">
                  <Text fw="bold">SDI</Text>
                  <Skeleton visible={isLoadingClientProfile}>
                    {clientProfile.sdi || ''}
                  </Skeleton>
                </Group>
              </>
            )}
          </Card>
        </Grid.Col>

        <Grid.Col span={{ lg: 6 }}>
          {user?.role === 'client' && (
            <Card title="Società affiliata">
              {!isLoadingClientProfile && !clientProfile.sellerCode ? (
                <Text>Nessuna società affiliata.</Text>
              ) : (
                <>
                  <Group
                    key={clientProfile.sellerCode!.code}
                    justify="space-between"
                  >
                    <Text>
                      <i>{clientProfile.sellerCode!.code}</i> -{' '}
                      {clientProfile.sellerCode!.seller.name},{' '}
                      {clientProfile.sellerCode!.seller.email}
                    </Text>
                    <Tooltip label="Rimuovi affiliazione">
                      <ActionIcon
                        color="red"
                        onClick={() =>
                          handleRemoveSellerCodeConnectionClick(
                            clientProfile.sellerCode!.code,
                          )
                        }
                        loading={isLoadingRemoveSellerCodeConnection}
                      >
                        <IconTrash />
                      </ActionIcon>
                    </Tooltip>
                  </Group>
                </>
              )}
              {!clientProfile.sellerCode && (
                <Button
                  mt="md"
                  leftSection={<IconPlus />}
                  onClick={handleAddSellerCodeConnectionClick}
                >
                  Aggiungi società affiliata
                </Button>
              )}
            </Card>
          )}
          {/* <Card title="Contatti commerciale">
            {!isLoadingSeller && !seller ? (
              <Text>Nessun commerciale assegnato.</Text>
            ) : (
              <>
                <Group justify="space-between">
                  <Text fw="bold">Nominativo</Text>
                  <Skeleton visible={isLoadingSeller}>{seller!.name}</Skeleton>
                </Group>
                <Group justify="space-between">
                  <Text fw="bold">Email</Text>
                  <Skeleton visible={isLoadingSeller}>
                    <Anchor href={`mailto:${seller!.email}`}>
                      {seller!.email}
                    </Anchor>
                  </Skeleton>
                </Group>
                <Group justify="space-between">
                  <Text fw="bold">Telefono</Text>
                  <Skeleton visible={isLoadingSeller}>
                    <Anchor href={`tel:${seller!.phone}`}>
                      {seller!.phone}
                    </Anchor>
                  </Skeleton>
                </Group>
              </>
            )}
          </Card> */}
        </Grid.Col>
      </Grid>

      <Divider
        label="Indirizzi"
        labelPosition="left"
        my="lg"
        styles={{
          label: {
            fontSize: rem('1.3rem'),
            fontWeight: 600,
            color: 'var(--mantine-color-text)',
          },
        }}
      />

      <Grid>
        {/* GRID ROW */}
        <Grid.Col span={{ lg: 6 }}>
          <Card
            title="Indirizzo di fatturazione"
            action={{
              title: 'Modifica',
              icon: <IconPencil />,
              onClick: handleEditProfileClick,
            }}
          >
            <Skeleton visible={isLoadingClientProfile}>
              {clientProfile.address}
            </Skeleton>
            <Skeleton visible={isLoadingClientProfile}>
              {clientProfile.zip} - {clientProfile.city} (
              {clientProfile.province})
            </Skeleton>
          </Card>
        </Grid.Col>

        <Grid.Col span={{ lg: 6 }}>
          <Card
            title="Indirizzi di spedizione"
            action={
              !isLoadingAddresses && addresses.length > 0
                ? {
                    title: 'Aggiungi',
                    icon: <IconPlus />,
                    onClick: handleAddAddressClick,
                  }
                : undefined
            }
          >
            {!isLoadingAddresses && addresses.length === 0 ? (
              <>
                <Text>Nessun indirizzo di spedizione</Text>
                <Button
                  onClick={handleAddAddressClick}
                  mt="md"
                  leftSection={<IconPlus />}
                >
                  Aggiungi indirizzo di spezione
                </Button>
              </>
            ) : (
              <Stack>
                {addresses.map((address) => (
                  <Group key={address.id} justify="space-between">
                    <div>
                      <Skeleton visible={isLoadingAddresses}>
                        <Text fw="bold">{address.name}</Text>
                      </Skeleton>
                      <Skeleton visible={isLoadingAddresses}>
                        {address.zip} - {address.city} ({address.province})
                      </Skeleton>
                      <Skeleton visible={isLoadingAddresses}>
                        {address.address}
                      </Skeleton>
                      {address.phone && address.phone.trim() !== '' && (
                        <Skeleton visible={isLoadingAddresses}>
                          {address.phone}
                        </Skeleton>
                      )}
                    </div>
                    <Group gap="xs">
                      <Tooltip label="Modifica">
                        <ActionIcon
                          onClick={() => handleEditAddressClick(address)}
                        >
                          <IconPencil />
                        </ActionIcon>
                      </Tooltip>
                      <Tooltip label="Elimina">
                        <ActionIcon
                          onClick={() => handleDeleteAddressClick(address.id)}
                          color="red"
                        >
                          <IconTrash />
                        </ActionIcon>
                      </Tooltip>
                    </Group>
                  </Group>
                ))}
              </Stack>
            )}
          </Card>
        </Grid.Col>
      </Grid>
    </>
  );
}
