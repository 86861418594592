import { Address } from '@interfaces/address.interface';

import api from './';

export interface CreateAddressBody {
  name: string;
  province: string;
  city: string;
  zip: string;
  address: string;
  phone?: string;
}

export interface UpdateAddressRequest {
  id: number;
  body: CreateAddressBody;
}

export const {
  useGetAddressesQuery,
  useCreateAddressMutation,
  useEditAddressMutation,
  useDeleteAddressMutation,
} = api.injectEndpoints({
  endpoints: (builder) => ({
    getAddresses: builder.query<Address[], void>({
      query: () => ({
        url: '/addresses',
      }),
      providesTags: ['addresses'],
    }),
    createAddress: builder.mutation<Address, CreateAddressBody>({
      query: (body) => ({
        url: '/addresses',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['addresses'],
    }),
    editAddress: builder.mutation<Address, UpdateAddressRequest>({
      query: ({ id, body }) => ({
        url: `/addresses/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['addresses'],
    }),
    deleteAddress: builder.mutation<Address, number>({
      query: (id) => ({
        url: `/addresses/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['addresses'],
    }),
  }),
});
