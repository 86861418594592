import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';

import {
  Anchor,
  Button,
  LoadingOverlay,
  PasswordInput,
  Text,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';

import { isApiError } from '@api/index';
import {
  useCheckTokenQuery,
  usePasswordResetMutation,
} from '@api/passwordReset.api';

import useAuth from '@hooks/useAuth';
import useGRecaptcha from '@hooks/useGRecaptcha';

import FormsPaper from '@components/FormsPaper';

export default function PasswordReset() {
  // ==========================================================================
  // General
  // ==========================================================================
  const { token } = useParams();

  const { isAuthenticated } = useAuth();

  const navigate = useNavigate();

  const { getRecaptchaToken } = useGRecaptcha();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    password: '',
    passwordConfirm: '',
  };

  const form = useForm({
    initialValues,
    validate: {
      passwordConfirm: (value, values) =>
        value !== values.password ? 'Le password non corrispondono.' : null,
    },
  });

  const onSubmit = async (values: typeof initialValues) => {
    try {
      const recaptchaToken = await getRecaptchaToken('PasswordReset');

      await passwordReset({
        token: token!,
        body: {
          password: values.password,
        },
        recaptchaToken,
      }).unwrap();

      showNotification({
        title: 'Password modificata correttamente!',
        message: 'Ora puoi accedere utilizzando la tua nuova password.',
      });

      navigate('/');
    } catch (e) {
      if (isApiError(e)) {
        if (e.status === 400) {
          form.setErrors({ general: e.data.message, ...e.data.errors });
        } else if (e.status === 404) {
          showNotification({
            title: 'Url non valido!',
            message:
              'Url per il ripristino della password non valido o scaduto.',
            color: 'red',
          });

          navigate('/');
        }
      } else {
        form.setErrors({
          general: 'Errore inatteso. Per favore riprova più tardi.',
        });
      }
    }
  };

  // ==========================================================================
  // Api
  // ==========================================================================
  const { isError, isLoading: isLoadingCheckToken } = useCheckTokenQuery(
    token!,
  );

  const [passwordReset, { isLoading }] = usePasswordResetMutation();

  // ==========================================================================
  // Render
  // ==========================================================================
  return isAuthenticated ? (
    <Navigate to="/ordini" replace={true} />
  ) : (
    <>
      <LoadingOverlay visible={isLoadingCheckToken} />

      <Text ta="center">
        Sai già la tua password?{' '}
        <Anchor to="/" component={Link}>
          Accedi
        </Anchor>
      </Text>

      <FormsPaper mt={30}>
        {isError ? (
          <>
            <Text size="sm">
              Link di ripristino password non valido o scaduto.
            </Text>
            <Anchor to="/password-reset" component={Link} size="sm">
              Richiedine uno nuovo
            </Anchor>
          </>
        ) : (
          <form
            onSubmit={form.onSubmit((values) => {
              onSubmit(values);
            })}
          >
            <Title order={2} ta="center" mb="lg">
              Recupera password
            </Title>
            <Text ta="center" size="sm" mb="lg">
              Inserisci una nuova password.
            </Text>
            <PasswordInput
              label="Nuova password"
              placeholder="La tua nuova password"
              required
              {...form.getInputProps('password')}
            />
            <PasswordInput
              mt="md"
              label="Ripeti password"
              placeholder="La tua nuova password"
              required
              {...form.getInputProps('passwordConfirm')}
            />
            {form.errors.general && (
              <Text c="red" size="sm" mt="xl">
                {form.errors.general}
              </Text>
            )}
            <Button type="submit" fullWidth mt="xl" loading={isLoading}>
              Conferma
            </Button>
          </form>
        )}
      </FormsPaper>
    </>
  );
}
