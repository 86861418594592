import { Button, Group, Select, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { closeAllModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { handleSubmitError } from '@utils/forms';

import { Address } from '@interfaces/address.interface';

import {
  useCreateAddressMutation,
  useEditAddressMutation,
} from '@api/addresses.api';
import { useGetCitiesQuery, useGetProvincesQuery } from '@api/locations.api';

interface UpsertAddressFormProps {
  address?: Address;
}

export default function UpsertAddressForm({ address }: UpsertAddressFormProps) {
  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    name: address?.name || '',
    nation: address?.nation || '',
    province: address?.province || '',
    city: address?.city || '',
    zip: address?.zip || '',
    address: address?.address || '',
    phone: address?.phone || '',
  };

  const form = useForm({
    initialValues,
  });

  // ==========================================================================
  // Api
  // ==========================================================================
  const { data: provinces = [], isLoading: isLoadingProvinces } =
    useGetProvincesQuery();

  const { data: cities = [], isLoading: isLoadingCities } = useGetCitiesQuery(
    form.values.province,
    { skip: form.values.province === '' },
  );

  const [createAddress, { isLoading: isLoadingCreateAddress }] =
    useCreateAddressMutation();
  const [editAddress, { isLoading: isLoadingEditAddress }] =
    useEditAddressMutation();

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onSubmit = async (values: typeof initialValues) => {
    try {
      if (address) {
        await editAddress({ id: address.id, body: values }).unwrap();
      } else {
        await createAddress(values).unwrap();
      }

      closeAllModals();

      showNotification({
        title: `Indirizzo ${address ? 'modificato' : 'aggiunto'}`,
        message: `Indirizzo di spedizione ${address ? 'modificato' : 'aggiunto'} con successo`,
      });
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <TextInput
        mt="xs"
        label="Nome"
        placeholder="Nome e cognome"
        required
        {...form.getInputProps('name')}
      />
      <Select
        mt="xs"
        label="Provincia"
        placeholder="Seleziona provincia"
        searchable
        nothingFoundMessage="Nessun risultato"
        required
        disabled={isLoadingProvinces}
        data={provinces.map((province) => ({
          value: province.sigla,
          label: province.sigla,
        }))}
        {...form.getInputProps('province')}
        onChange={(e) => {
          form.setFieldValue('city', '');
          form.setFieldValue('zip', '');
          form.getInputProps('province').onChange(e);
        }}
      />
      <Select
        mt="xs"
        label="Città"
        placeholder="Seleziona città"
        searchable
        nothingFoundMessage="Nessun risultato"
        required
        disabled={form.values.province === '' || isLoadingCities}
        data={cities.map((city) => ({
          value: city.nome,
          label: city.nome,
        }))}
        {...form.getInputProps('city')}
        onChange={(e) => {
          form.setFieldValue('zip', '');
          form.getInputProps('city').onChange(e);
        }}
      />
      <Select
        mt="xs"
        label="CAP"
        placeholder="Seleziona CAP"
        searchable
        nothingFoundMessage="Nessun risultato"
        required
        disabled={form.values.city === '' || isLoadingCities}
        data={[
          {
            value: cities.find((c) => c.nome === form.values.city)?.cap || '',
            label: cities.find((c) => c.nome === form.values.city)?.cap || '',
          },
        ]}
        {...form.getInputProps('zip')}
      />
      <TextInput
        mt="xs"
        label="Indirizzo"
        placeholder="Via e numero civico"
        required
        {...form.getInputProps('address')}
      />
      <TextInput
        mt="xs"
        label="Telefono"
        placeholder="Numero di telefono"
        {...form.getInputProps('phone')}
      />
      {form.errors.general && (
        <Text c="red" size="sm" mt="xl">
          {form.errors.general}
        </Text>
      )}
      <Group mt="xl">
        <Button
          variant="default"
          onClick={() => closeAllModals()}
          loading={isLoadingCreateAddress || isLoadingEditAddress}
        >
          Annulla
        </Button>
        <Button
          type="submit"
          loading={isLoadingCreateAddress || isLoadingEditAddress}
        >
          Conferma
        </Button>
      </Group>
    </form>
  );
}
