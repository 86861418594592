import { generateMockValue } from '../../utils/generateMockData';

export function generateGetSingleOrderMock() {
  return generateMockValue({
    type: 'object',
    values: {
      id: 'string',
      customerRef: 'string',
      date: 'date',
      shippingMethod: 'ShippingMethod',
      shippingDate: 'date',
      trackingUrl: 'null',
      status: 'OrderStatus',
      paymentType: 'PaymentType',
      subtotal: 'number',
      shippingCost: 'number',
      couponDiscount: 'number',
      netTotal: 'number',
      taxes: 'number',
      total: 'number',
      couponCode: 'null',
      invoicingName: 'string',
      invoicingVatTaxNumber: 'string',
      invoicingPec: 'null',
      invoicingSdi: 'null',
      invoicingProvince: 'string',
      invoicingCity: 'string',
      invoicingZip: 'string',
      invoicingAddress: 'string',
      shippingName: 'string',
      shippingPhone: 'string',
      shippingNation: 'string',
      shippingProvince: 'string',
      shippingCity: 'string',
      shippingZip: 'string',
      shippingAddress: 'string',
      note: 'null',
      clientId: 'number',
      sellerCode: 'null',
      sellerCommission: 'null',
      source: 'OrderSource',
      createdAt: 'date',
      updatedAt: 'date',
    },
  });
}

export function generateGetOrdersMock() {
  return generateMockValue({
    type: 'array',
    minLength: 5,
    maxLength: 5,
    each: {
      type: 'object',
      values: {
        id: 'string',
        customerRef: 'string',
        date: 'date',
        shippingMethod: 'ShippingMethod',
        shippingDate: 'date',
        trackingUrl: 'null',
        status: 'OrderStatus',
        paymentType: 'PaymentType',
        subtotal: 'number',
        shippingCost: 'number',
        couponDiscount: 'number',
        netTotal: 'number',
        taxes: 'number',
        total: 'number',
        couponCode: 'null',
        invoicingName: 'string',
        invoicingVatTaxNumber: 'string',
        invoicingPec: 'null',
        invoicingSdi: 'null',
        invoicingProvince: 'string',
        invoicingCity: 'string',
        invoicingZip: 'string',
        invoicingAddress: 'string',
        shippingName: 'string',
        shippingPhone: 'string',
        shippingNation: 'string',
        shippingProvince: 'string',
        shippingCity: 'string',
        shippingZip: 'string',
        shippingAddress: 'string',
        note: 'null',
        clientId: 'number',
        sellerCode: 'null',
        sellerCommission: 'null',
        source: 'OrderSource',
        createdAt: 'date',
        updatedAt: 'date',
      },
    },
  });
}

export function generateGetSingleAffiliatedOrderMock() {
  return generateMockValue({
    type: 'object',
    values: {
      id: 'string',
      customerRef: 'string',
      date: 'date',
      shippingMethod: 'ShippingMethod',
      shippingDate: 'date',
      status: 'OrderStatus',
      paymentType: 'PaymentType',
      subtotal: 'number',
      shippingCost: 'number',
      couponDiscount: 'number',
      netTotal: 'number',
      taxes: 'number',
      total: 'number',
      couponCode: 'null',
      invoicingProvince: 'string',
      invoicingCity: 'string',
      invoicingZip: 'string',
      shippingNation: 'string',
      shippingProvince: 'string',
      shippingCity: 'string',
      shippingZip: 'string',
      sellerCode: 'null',
      sellerCommission: 'null',
      source: 'null',
      createdAt: 'string',
      updatedAt: 'string',
    },
  });
}

export function generateGetAffiliatedOrdersMock() {
  return generateMockValue({
    type: 'array',
    minLength: 5,
    maxLength: 5,
    each: {
      type: 'object',
      values: {
        id: 'string',
        customerRef: 'string',
        date: 'date',
        shippingMethod: 'ShippingMethod',
        shippingDate: 'date',
        status: 'OrderStatus',
        paymentType: 'PaymentType',
        subtotal: 'number',
        shippingCost: 'number',
        couponDiscount: 'number',
        netTotal: 'number',
        taxes: 'number',
        total: 'number',
        couponCode: 'null',
        invoicingProvince: 'string',
        invoicingCity: 'string',
        invoicingZip: 'string',
        shippingNation: 'string',
        shippingProvince: 'string',
        shippingCity: 'string',
        shippingZip: 'string',
        sellerCode: 'null',
        sellerCommission: 'null',
        source: 'null',
        createdAt: 'string',
        updatedAt: 'string',
      },
    },
  });
}

export function generateGetOrdersProductsMock() {
  return generateMockValue({
    type: 'array',
    minLength: 5,
    maxLength: 5,
    each: {
      type: 'object',
      values: {
        id: 'string',
        sku: 'string',
        name: 'string',
        imageUrl: 'string',
        color: 'string',
        size: 'string',
        quantity: 'number',
        totalPrice: 'number',
        salePercent: 'number',
        shippingDate: 'string',
        draftProductsRelationId: 'string',
        status: 'OrderProductStatus',
      },
    },
  });
}
