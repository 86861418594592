import { Link } from 'react-router-dom';

import { Button, Center, Stack, Title } from '@mantine/core';

export default function NotFound() {
  return (
    <Center>
      <Stack align="center">
        <Title>La pagina che cerchi non esiste</Title>
        <Button to="/" component={Link}>
          Torna alla pagina iniziale
        </Button>
      </Stack>
    </Center>
  );
}
