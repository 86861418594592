import { Link } from 'react-router-dom';

import { ActionIcon, Badge, Tooltip } from '@mantine/core';
import { IconBell } from '@tabler/icons-react';

import classes from './IconBadge.module.css';

interface IconBadgeProps {
  tooltipLabel: string;
  badgeText?: string | null;
  to: string;
}

export default function IconBadge({
  tooltipLabel,
  badgeText,
  to,
}: IconBadgeProps) {
  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <Tooltip label={tooltipLabel}>
      <ActionIcon
        to={to}
        component={Link}
        className={classes.container}
        size="lg"
        variant="subtle"
      >
        <IconBell />
        {badgeText && (
          <Badge className={classes.badge} size="xs">
            {badgeText}
          </Badge>
        )}
      </ActionIcon>
    </Tooltip>
  );
}
