import {
  Loader,
  MantineThemeOverride,
  Paper,
  Skeleton,
  Switch,
} from '@mantine/core';

const theme: MantineThemeOverride = {
  fontFamily: 'Montserrat, sans-serif',
  headings: {
    fontFamily: 'Montserrat, sans-serif',
    sizes: {
      h1: {
        fontSize: '1.5rem',
      },
      h2: {
        fontSize: '1.2rem',
      },
    },
  },

  black: '#55607c',

  colors: {
    brand: [
      '#fff3e3',
      '#ffe6cd',
      '#ffcb9c',
      '#feae65',
      '#fe9739',
      '#fe871d',
      '#fe7f0d',
      '#e36c00',
      '#ca5f00',
      '#b05100',
    ],
  },
  primaryColor: 'brand',

  defaultRadius: 0,

  components: {
    Loader: Loader.extend({
      defaultProps: {
        type: 'dots',
      },
    }),
    Paper: Paper.extend({
      defaultProps: {
        withBorder: true,
        // shadow: 'md',
      },
    }),
    Switch: Switch.extend({
      styles: {
        track: {
          cursor: 'pointer',
        },
      },
    }),
    Skeleton: Skeleton.extend({
      styles: {
        root: {
          width: 'max-content',
        },
      },
    }),
  },
};

export default theme;
