import { ClientProfile } from '@interfaces/clientsProfiles.interface';

import api from './';

export interface UpdateClientProfileBody {
  phone?: string;
  pec?: string | null;
  sdi?: string | null;
  address?: string;
  city?: string;
  zip?: string;
  province?: string;
}

export const {
  useGetClientProfileQuery,
  useUpdateClientProfileMutation,
  useAddSellerCodeConnectionMutation,
  useRemoveSellerCodeConnectionMutation,
  useGetAffiliationCountQuery,
} = api.injectEndpoints({
  endpoints: (build) => ({
    getClientProfile: build.query<ClientProfile, void>({
      query: () => ({
        url: 'clients-profiles/me',
      }),
      providesTags: ['clientProfile'],
    }),
    updateClientProfile: build.mutation<ClientProfile, UpdateClientProfileBody>(
      {
        query: (body) => ({
          url: 'clients-profiles/me',
          method: 'PATCH',
          body,
        }),
        invalidatesTags: ['clientProfile'],
      },
    ),
    addSellerCodeConnection: build.mutation<void, string>({
      query: (code) => ({
        url: `clients-profiles/me/seller-code/${code}`,
        method: 'POST',
      }),
      invalidatesTags: ['clientProfile'],
    }),
    removeSellerCodeConnection: build.mutation<void, string>({
      query: (code) => ({
        url: `clients-profiles/me/seller-code/${code}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['clientProfile'],
    }),
    getAffiliationCount: build.query<number, void>({
      query: () => ({
        url: '/clients-profiles/affiliations-count',
      }),
    }),
  }),
});
