import { UserRole } from '@interfaces/auth.interface';
import { ClientType } from '@interfaces/clientsProfiles.interface';

export const mapUsersRoles = (role: UserRole): string => {
  switch (role) {
    case 'admin':
      return 'Admin';
    case 'seller':
      return 'Rivenditore';
    case 'client':
      return 'Cliente';
  }
};

export const mapUsersType = (role: ClientType): string => {
  switch (role) {
    case 'private':
      return 'Privato';
    case 'company':
      return 'Azienda';
  }
};
