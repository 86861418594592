import { generateMockValue } from '../../utils/generateMockData';

export function generateGetReceiptsMock() {
  return generateMockValue({
    type: 'array',
    minLength: 5,
    maxLength: 5,
    each: {
      type: 'object',
      values: {
        id: 'string',
        date: 'string',
        url: 'string',
        userId: 'number',
      },
    },
  });
}
