import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import {
  Button,
  Loader,
  PasswordInput,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { handleSubmitError } from '@utils/forms';

import {
  useActivateMutation,
  useVerifyActivationTokenQuery,
} from '@api/users.api';

export default function ActivateUser() {
  // ==========================================================================
  // General
  // ==========================================================================
  const { token } = useParams();

  // ==========================================================================
  // State
  // ==========================================================================
  const [verified, setVerified] = useState(false);

  // ==========================================================================
  // Api
  // ==========================================================================
  const { data, isLoading, error } = useVerifyActivationTokenQuery(token!);
  const [activateUser, { isLoading: isLoadingActivate }] =
    useActivateMutation();

  useEffect(() => {
    const f = async () => {
      if (!error && data && data?.requiresPassword) {
        try {
          await activateUser({ token: token!, body: {} }).unwrap();
          setVerified(true);
        } catch (e) {
          console.error(e);
          showNotification({
            color: 'red',
            title: 'Errore',
            message: "Errore nell'attivazione dell'account",
          });
        }
      }
    };

    f();
  }, [data, error, activateUser, token]);

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    password: '',
    passwordConfirm: '',
  };

  const form = useForm({
    initialValues,
    validate: {
      passwordConfirm: (value, values) =>
        value !== values.password ? 'Le password non corrispondono.' : null,
    },
  });

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onSubmit = async (values: typeof initialValues) => {
    try {
      const data = {
        password: values.password,
      };

      await activateUser({ token: token!, body: data }).unwrap();

      setVerified(true);
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <Stack mt="xl" align="center">
      {isLoading || isLoadingActivate ? (
        <Loader />
      ) : error ? (
        <>
          <Text ta="center" fw="bold">
            Token non valido o scaduto
          </Text>
          <Button component={Link} to="/">
            Richiedi nuova verifica
          </Button>
        </>
      ) : verified ? (
        <>
          <Text ta="center" fw="bold">
            Il tuo account è stato correttamente verificato
          </Text>
          <Button component={Link} to="/">
            Accedi
          </Button>
        </>
      ) : (
        <Stack>
          <Title>Concludi registrazione</Title>
          <Text ta="center">Imposta la password per proseguire</Text>
          <form
            onSubmit={form.onSubmit((values) => {
              onSubmit(values);
            })}
          >
            <PasswordInput
              label="Password"
              placeholder="La tua password"
              required
              {...form.getInputProps('password')}
            />
            <PasswordInput
              label="Ripeti password"
              placeholder="La tua password"
              required
              mt="md"
              {...form.getInputProps('passwordConfirm')}
            />
            {form.errors.general && (
              <Text c="red" size="sm" mt="xl">
                {form.errors.general}
              </Text>
            )}

            <Button type="submit" fullWidth mt="xl" loading={isLoadingActivate}>
              Conferma
            </Button>
          </form>
        </Stack>
      )}
    </Stack>
  );
}
