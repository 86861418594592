import { Button, Group, Select, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { closeAllModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { handleSubmitError } from '@utils/forms';

import { Order } from '@interfaces/orders.interface';

import { useGetCitiesQuery, useGetProvincesQuery } from '@api/locations.api';
import { useUpdateOrderMutation } from '@api/orders.api';

interface EditOrderShippingAddressProps {
  order: Order;
}

export default function EditOrderShippingAddressForm({
  order,
}: EditOrderShippingAddressProps) {
  const initialValues = {
    shippingName: order.shippingName,
    shippingAddress: order.shippingAddress,
    shippingCity: order.shippingCity,
    shippingZip: order.shippingZip,
    shippingProvince: order.shippingProvince,
  };

  const form = useForm({
    initialValues,
  });

  const { data: provinces = [], isLoading: isLoadingProvinces } =
    useGetProvincesQuery();

  const { data: cities = [], isLoading: isLoadingCities } = useGetCitiesQuery(
    form.values.shippingProvince,
    { skip: form.values.shippingProvince === '' },
  );

  const [updateOrder, { isLoading }] = useUpdateOrderMutation();

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await updateOrder({ id: order.id, body: values }).unwrap();

      closeAllModals();

      showNotification({
        title: 'Informazioni modificate',
        message: "L'indirizzo di consegna è stato modificato con successo",
      });
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <TextInput
        mt="xs"
        label="Nome"
        placeholder="Nome Cognome"
        required
        {...form.getInputProps('shippingName')}
      />
      <Select
        mt="xs"
        label="Provincia"
        placeholder="Seleziona provincia"
        searchable
        nothingFoundMessage="Nessun risultato"
        required
        disabled={isLoadingProvinces}
        data={provinces.map((province) => ({
          value: province.sigla,
          label: province.sigla,
        }))}
        {...form.getInputProps('shippingProvince')}
        onChange={(e) => {
          form.setFieldValue('city', '');
          form.setFieldValue('zip', '');
          form.getInputProps('shippingProvince').onChange(e);
        }}
      />
      <Select
        mt="xs"
        label="Città"
        placeholder="Seleziona città"
        searchable
        nothingFoundMessage="Nessun risultato"
        required
        disabled={form.values.shippingProvince === '' || isLoadingCities}
        data={cities.map((city) => ({
          value: city.nome,
          label: city.nome,
        }))}
        {...form.getInputProps('shippingCity')}
        onChange={(e) => {
          form.setFieldValue('shippingZip', '');
          form.getInputProps('shippingCity').onChange(e);
        }}
      />
      <Select
        mt="xs"
        label="CAP"
        placeholder="Seleziona CAP"
        searchable
        nothingFoundMessage="Nessun risultato"
        required
        disabled={form.values.shippingCity === '' || isLoadingCities}
        data={[
          {
            value:
              cities.find((c) => c.nome === form.values.shippingCity)?.cap ||
              '',
            label:
              cities.find((c) => c.nome === form.values.shippingCity)?.cap ||
              '',
          },
        ]}
        {...form.getInputProps('shippingZip')}
      />
      <TextInput
        mt="xs"
        label="Indirizzo"
        placeholder="Via Principale, 20"
        required
        {...form.getInputProps('shippingAddress')}
      />
      {form.errors.general && (
        <Text c="red" size="sm" mt="xl">
          {form.errors.general}
        </Text>
      )}
      <Group mt="xl">
        <Button
          variant="default"
          onClick={() => closeAllModals()}
          loading={isLoading}
        >
          Annulla
        </Button>
        <Button type="submit" loading={isLoading}>
          Conferma
        </Button>
      </Group>
    </form>
  );
}
