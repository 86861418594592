import { NavLink } from 'react-router-dom';

import { rem, Stack, ThemeIcon } from '@mantine/core';

import useUserRoleGuard from '@hooks/useUserRoleGuard';

import { linksData } from '../../../data/linksData';
import classes from './BottomNavbar.module.css';

export default function BottomNavbar() {
  const userIsClient = useUserRoleGuard('client');
  const userIsSeller = useUserRoleGuard('seller');
  const userIsAdmin = useUserRoleGuard('admin');

  const links = linksData
    .filter(
      (item) =>
        (item.roles?.includes('client') && userIsClient) ||
        (item.roles?.includes('seller') && userIsSeller) ||
        (item.roles?.includes('admin') && userIsAdmin),
    )
    .map((item) => (
      <NavLink key={item.label} to={item.link} className={classes.link}>
        <Stack align="center" gap="xs">
          <ThemeIcon variant="light" size={30}>
            <item.icon style={{ width: rem(18), height: rem(18) }} />
          </ThemeIcon>
          <span className={classes.linkText}>{item.label}</span>
        </Stack>
      </NavLink>
    ));

  return <nav className={classes.navbar}>{links}</nav>;
}
