import { generateMockValue } from '@utils/generateMockData';

export const generateDataNotifications = (count: number) => {
  return generateMockValue({
    type: 'array',
    minLength: count,
    maxLength: count,
    each: {
      type: 'object',
      values: {
        id: 'number',
        title: 'string',
        description: 'string',
        category: 'NotificationCategory',
        link: 'string',
        payload: 'string',
        read: 'boolean',
        archived: 'boolean',
        createdAt: 'date',
        userId: 'number',
      },
    },
  });
};
