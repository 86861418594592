import { useLayoutEffect } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { Anchor, Badge, Button, Group, Text, Tooltip } from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconTrash, IconX } from '@tabler/icons-react';

import {
  useActivateUserMutation,
  useDeactivateUserMutation,
  useDeleteUserMutation,
  useGetSingleUserQuery,
} from '@api/users.api';

import useAuth from '@hooks/useAuth';

import Card from '@components/card/Card';
import { LayoutContextType } from '@components/layout/Layout';

import classes from './UserDetails.module.css';

export default function UserDetails() {
  // ==========================================================================
  // General
  // ==========================================================================
  const { id } = useParams();
  const { user } = useAuth();

  const navigate = useNavigate();

  // ==========================================================================
  // Api
  // ==========================================================================
  const { data } = useGetSingleUserQuery(+id!);
  const [activateUser] = useActivateUserMutation();
  const [deactivateUser] = useDeactivateUserMutation();
  const [deleteUser] = useDeleteUserMutation();

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handleToggleActivation = () => {
    if (data) {
      modals.openConfirmModal({
        title: data.active ? 'Disabilita utente' : 'Abilita utente',
        children: data.active
          ? "Disabilitando l'utente questo non potrà più accedere alla sua area riservata. Proseguire?"
          : "Abilitando l'utente questo potrà nuovamente accedere alla sua area riservata. Proseguire?",
        labels: {
          cancel: 'Annulla',
          confirm: data.active ? 'Disabilita' : 'Abilita',
        },
        confirmProps: { color: data.active ? 'red' : 'green' },
        onConfirm: () =>
          data.active ? deactivateUser(data.id) : activateUser(data.id),
      });
    }
  };

  const handleDeleteUser = () => {
    if (data) {
      modals.openConfirmModal({
        title: 'Elimina utente',
        children:
          "Stai per eliminare l'utente. L'operazione non è reversibile. Proseguire?",
        labels: {
          cancel: 'Annulla',
          confirm: 'Elimina utente',
        },
        confirmProps: { color: 'red' },
        onConfirm: async () => {
          try {
            await deleteUser(data.id).unwrap();

            showNotification({
              title: 'Utente eliminato',
              message: 'Utente eliminato con successo',
            });

            navigate('/utenti');
          } catch (e) {
            showNotification({
              title: 'Errore',
              message: "Impossibile eliminare l'utente",
              color: 'red',
              icon: <IconX />,
            });
          }
        },
      });
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  const { setLayoutProps } = useOutletContext<LayoutContextType>();

  useLayoutEffect(() => {
    setLayoutProps({
      title: `Dettagli ${data?.name || ''}`,
      backLink: {
        title: 'Elenco utenti',
        to: '/utenti',
      },
    });
  }, [setLayoutProps, data]);

  return (
    <>
      {data && (
        <Group grow align="start">
          <Card title="Informazioni">
            <Group justify="space-between">
              <Text fw="bold">Stato</Text>
              {data.id === user?.id ? (
                <Badge color="green" radius="md">
                  Attivo
                </Badge>
              ) : data.active ? (
                <Tooltip
                  label="Premi per disabilitare"
                  className={classes.statusBadge}
                  onClick={handleToggleActivation}
                >
                  <Badge color="green" radius="md">
                    Attivo
                  </Badge>
                </Tooltip>
              ) : (
                <Tooltip
                  label="Premi per abilitare"
                  className={classes.statusBadge}
                  onClick={handleToggleActivation}
                >
                  <Badge color="red" radius="md">
                    Non attivo
                  </Badge>
                </Tooltip>
              )}
            </Group>
            {/*<Group justify="space-between">
              <Text fw="bold">Tipologia</Text>
              <Text>{mapUsersType(data.type)}</Text>
            </Group>
             {data.type === 'private' ? (
              <>
                <Group justify="space-between">
                  <Text fw="bold">Nome</Text>
                  <Text>{data.name}</Text>
                </Group>
                <Group justify="space-between">
                  <Text fw="bold">Cognome</Text>
                  <Text>{data.surname}</Text>
                </Group>
                <Group justify="space-between">
                  <Text fw="bold">Codice fiscale</Text>
                  <Text>{data.vatTaxNumber}</Text>
                </Group>
              </>
            ) : (
              <>
                <Group justify="space-between">
                  <Text fw="bold">Ragione sociale</Text>
                  <Text>{data.companyName}</Text>
                </Group>
                <Group justify="space-between">
                  <Text fw="bold">P. IVA</Text>
                  <Text>{data.vatTaxNumber}</Text>
                </Group>
              </>
            )} */}
          </Card>
          <Card title="Contatti">
            <Group justify="space-between">
              <Text fw="bold">Email</Text>
              <Anchor href={`mailto:${data.email}`}>{data.email}</Anchor>
            </Group>
            {/* <Group justify="space-between">
              <Text fw="bold">Telefono</Text>
              <Anchor href={`tel:${data.phone}`}>{data.phone}</Anchor>
            </Group>
            {data.pec && (
              <Group justify="space-between">
                <Text fw="bold">PEC</Text>
                <Anchor href={`mailto:${data.pec}`}>{data.pec}</Anchor>
              </Group>
            )}
            {data.sdi && (
              <Group justify="space-between">
                <Text fw="bold">SDI</Text>
                <Text>{data.sdi}</Text>
              </Group>
            )}
            <Group justify="space-between" wrap="nowrap">
              <Text fw="bold">Indirizzo</Text>
              <Text ta="right">
                {data.address}
                <br />
                {data.city} ({data.province}) - {data.zip}
              </Text>
            </Group> */}
          </Card>
          <Card title="Azioni">
            {data.id !== user?.id && (
              <Button
                color="red"
                leftSection={<IconTrash />}
                onClick={handleDeleteUser}
              >
                Elimina utente
              </Button>
            )}
          </Card>
        </Group>
      )}
    </>
  );
}
