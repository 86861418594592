import { useLayoutEffect } from 'react';
import { Navigate, useOutletContext, useParams } from 'react-router-dom';

import { useGetWebOrderIdQuery } from '@api/orders.api';

import { LayoutContextType } from '@components/layout/Layout';

import NotFound from './NotFound';

export default function OrderDetailWebRedirect() {
  // ==========================================================================
  // General
  // ==========================================================================
  const { id } = useParams();

  // ==========================================================================
  // Api
  // ==========================================================================
  const { data, isLoading, isError } = useGetWebOrderIdQuery(+id!);

  // ==========================================================================
  // Render
  // ==========================================================================
  const { setLayoutProps } = useOutletContext<LayoutContextType>();

  useLayoutEffect(() => {
    setLayoutProps({ loading: isLoading });
  }, [isLoading, setLayoutProps]);

  return isLoading ? (
    <></>
  ) : data && !isError ? (
    <Navigate to={`/ordini/${data.id}`} replace={true} />
  ) : (
    <NotFound />
  );
}
