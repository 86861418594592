import { useLayoutEffect, useState } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';

import { Anchor, Box, Group } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { IconFileTypePdf } from '@tabler/icons-react';
import { dateToDateString } from '@utils/date';

import { generateGetReceiptsMock } from '@api/mocks/receipts.mock';
import {
  GetReceiptsRequestParams,
  ReceiptsSortBy,
  useGetReceiptsCountQuery,
  useGetReceiptsQuery,
} from '@api/receipts.api';

import { LayoutContextType } from '@components/layout/Layout';
import PaginationRow from '@components/PaginationRow';
import SearchBar from '@components/SearchBar';
import SortableTable from '@components/sortableTable/SortableTable';

export default function Receipts() {
  // ==========================================================================
  // General
  // ==========================================================================
  const [searchParams] = useSearchParams();

  const selectedReceipt = searchParams.get('sel');

  // ==========================================================================
  // State
  // ==========================================================================
  const [filters, setFilters] = useState<GetReceiptsRequestParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 5),
    searchQuery: searchParams.get('search') || undefined,
  });

  const [searchQuery] = useDebouncedValue(filters.searchQuery, 200, {
    leading: true,
  });

  // ==========================================================================
  // Api
  // ==========================================================================

  // Get initial Receipts count
  const {
    data: receiptsCount = { count: 0 },
    isLoading: isLoadingReceiptsCount,
  } = useGetReceiptsCountQuery(searchQuery);

  const { data = generateGetReceiptsMock(), isLoading } = useGetReceiptsQuery({
    ...filters,
    searchQuery,
  });

  // Map data
  const receipts = data.map((receipt) => {
    const data = [
      <Box fw={(receipt.id === selectedReceipt && 'bold') || undefined}>
        {dateToDateString(new Date(receipt.date))}
      </Box>,
      <Box fw={(receipt.id === selectedReceipt && 'bold') || undefined}>
        {receipt.id}
      </Box>,
      <Anchor
        href={receipt.url}
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        <IconFileTypePdf />
      </Anchor>,
    ];

    return {
      key: receipt.id,
      data,
    };
  });

  // ==========================================================================
  // Render
  // ==========================================================================
  const { setLayoutProps } = useOutletContext<LayoutContextType>();

  useLayoutEffect(() => {
    setLayoutProps({
      title: 'Scontrini',
    });
  }, [setLayoutProps]);

  const totalPages = Math.ceil(receiptsCount.count / filters.pageLength!);

  return (
    <>
      <Group mb="lg">
        <SearchBar
          placeholder="Cerca numero scontrino"
          value={filters.searchQuery}
          onChange={(newValue) =>
            setFilters({ ...filters, searchQuery: newValue })
          }
        />
      </Group>
      <SortableTable
        data={receipts}
        headings={{
          date: 'Data',
          id: 'Numero scontrino',
          pdfLink: 'Pdf',
        }}
        sortableKeys={['id', 'date']}
        onSortingChange={(key, order) =>
          setFilters({
            ...filters,
            sortBy: key as ReceiptsSortBy,
            sortOrder: order,
          })
        }
        emptyText="Nessuno scontrino trovato"
        loading={isLoading || isLoadingReceiptsCount}
        minWidth="25rem"
      />
      <PaginationRow
        page={filters.page!}
        pageLength={filters.pageLength!}
        totalPages={totalPages}
        onPageChange={(newPage) => setFilters({ ...filters, page: newPage })}
        onPageLengthChange={(newPageLength) =>
          setFilters({ ...filters, pageLength: newPageLength, page: 1 })
        }
      />
    </>
  );
}
