import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { Alert, Loader } from '@mantine/core';
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe, StripePaymentElementChangeEvent } from '@stripe/stripe-js';
import { IconAlertCircle } from '@tabler/icons-react';

import env from '@config/env';

import { Order, StripePaymentIntent } from '@interfaces/orders.interface';

import { useCreateStripePaymentIntentMutation } from '@api/orders.api';

const stripePromise = loadStripe(env.STRIPE_PUBLIC_KEY, {
  apiVersion: '2024-04-10',
});

export interface StripePaymentFormProps {
  order: Order;
  onValidChange: (valid: boolean) => void;
}

export interface StripePaymentFormRef {
  submitForm: () => Promise<void>;
}

export default forwardRef(function StripePaymentForm(
  { order, onValidChange }: StripePaymentFormProps,
  ref,
) {
  // ==========================================================================
  // General
  // ==========================================================================
  const checkoutFormRef = useRef<StripePaymentFormRef>(null);

  // ==========================================================================
  // State
  // ==========================================================================
  const [stripePaymentIntent, setStripePaymentIntent] =
    useState<StripePaymentIntent>();

  // ==========================================================================
  // Api
  // ==========================================================================
  const [createStripePaymentIntent] = useCreateStripePaymentIntentMutation();

  // ==========================================================================
  // Handlers
  // ==========================================================================
  useImperativeHandle(ref, () => ({
    async submitForm() {
      await checkoutFormRef.current?.submitForm();
    },
  }));

  useEffect(() => {
    const f = async () => {
      const intent: StripePaymentIntent = await createStripePaymentIntent({
        orderId: order.id,
      }).unwrap();

      setStripePaymentIntent(intent);
    };

    f();
  }, []);

  // ==========================================================================
  // Render
  // ==========================================================================
  return stripePaymentIntent?.clientSecret ? (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret: stripePaymentIntent.clientSecret,
        appearance: {
          variables: { borderRadius: '0', colorPrimary: '#fe871d' },
        },
      }}
    >
      <CheckoutForm
        ref={checkoutFormRef}
        order={order}
        onValidChange={onValidChange}
      />
    </Elements>
  ) : (
    <Loader />
  );
});

const CheckoutForm = forwardRef(function CheckoutForm(
  { order, onValidChange }: StripePaymentFormProps,
  ref,
) {
  const stripe = useStripe();
  const elements = useElements();

  useImperativeHandle(ref, () => ({
    async submitForm(): Promise<void> {
      await handleSubmit();
    },
  }));

  const [message, setMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handlePaymentElementChange = (e: StripePaymentElementChangeEvent) => {
    if (e.complete) {
      onValidChange(true);
    } else {
      onValidChange(false);
    }
  };

  const handleSubmit = async (): Promise<void> => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.

      return;
    }

    setMessage(undefined);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${env.BASE_URL}/ordini/${order.id}`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.

    console.error(error);
    setMessage(error.message);
  };

  return (
    <>
      <PaymentElement onChange={handlePaymentElementChange} />
      {message && (
        <Alert
          mt="lg"
          variant="filled"
          color="red"
          title="Errore pagamento"
          icon={<IconAlertCircle />}
        >
          {message}
        </Alert>
      )}
    </>
  );
});
