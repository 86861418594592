import { useLayoutEffect, useState } from 'react';
import { Link, useOutletContext, useSearchParams } from 'react-router-dom';

import { ActionIcon, Button, Group, Tooltip } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { openModal } from '@mantine/modals';
import {
  IconAlertCircle,
  IconInfoSquareRoundedFilled,
  IconPlus,
} from '@tabler/icons-react';
import { mapUsersRoles } from '@utils/mappers';

import { UserRole } from '@interfaces/auth.interface';

import {
  GetUsersRequestParams,
  GetUsersSortBy,
  useCountUsersQuery,
  useGetUsersQuery,
} from '@api/users.api';

import CreatePasswordlessUserModal from '@components/CreatePasswordlessUserModal';
import { LayoutContextType } from '@components/layout/Layout';
import PaginationRow from '@components/PaginationRow';
import QSSelect from '@components/QSSelect';
import SearchBar from '@components/SearchBar';
import SortableTable from '@components/sortableTable/SortableTable';

export default function Users() {
  // ==========================================================================
  // State
  // ==========================================================================
  const [searchParams] = useSearchParams();

  const [filters, setFilters] = useState<GetUsersRequestParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 50),
    searchQuery: searchParams.get('search') || undefined,
    role: searchParams.get('role')
      ? (searchParams.get('role') as UserRole)
      : undefined,
  });

  const [searchQuery] = useDebouncedValue(filters.searchQuery, 200, {
    leading: true,
  });

  // ==========================================================================
  // Api
  // ==========================================================================
  const { data: userCount = { count: 0 }, isLoading: isLoadingCount } =
    useCountUsersQuery({ searchQuery });

  const { data = [], isLoading } = useGetUsersQuery({
    ...filters,
    searchQuery,
  });

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handleCreateUserClick = () => {
    openModal({
      title: 'Crea utente',
      children: <CreatePasswordlessUserModal />,
    });
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  const { setLayoutProps } = useOutletContext<LayoutContextType>();

  useLayoutEffect(() => {
    setLayoutProps({ title: 'Utenti' });
  }, [setLayoutProps]);

  // Map data
  const users = data.map((user) => {
    const data = [
      <Group gap="xs">
        {!user.active && (
          <Tooltip label="L'utente è disabilitato">
            <IconAlertCircle color="red" size={17} />
          </Tooltip>
        )}
        {user.name}
      </Group>,
      user.email,
      mapUsersRoles(user.role),
      <Tooltip label="Dettagli utente">
        <ActionIcon variant="subtle" component={Link} to={`/utenti/${user.id}`}>
          <IconInfoSquareRoundedFilled />
        </ActionIcon>
      </Tooltip>,
    ];

    return {
      key: user.id,
      data,
    };
  });

  const totalPages = Math.ceil(userCount.count / filters.pageLength!);

  return (
    <>
      <Group mb="lg" justify="space-between">
        <Group>
          <QSSelect
            placeholder="Tutti i ruoli"
            data={[
              { label: mapUsersRoles('admin'), value: 'admin' },
              { label: mapUsersRoles('seller'), value: 'seller' },
              { label: mapUsersRoles('client'), value: 'client' },
            ]}
            value={filters.role || null}
            onChange={(value) => {
              setFilters({
                ...filters,
                role: value ? (value as UserRole) : undefined,
              });
            }}
            qsKey="role"
          />
          <SearchBar
            placeholder="Ricerca per nominativo/email"
            value={filters.searchQuery}
            onChange={(value) => setFilters({ ...filters, searchQuery: value })}
          />
        </Group>
        <Button onClick={handleCreateUserClick} leftSection={<IconPlus />}>
          Crea utente
        </Button>
      </Group>
      <SortableTable
        data={users}
        headings={{
          name: 'Nominativo',
          email: 'Email',
          role: 'Ruolo',
          actions: '',
        }}
        sortableKeys={['email']}
        emptyText="Nessun utente trovato per i filtri selezionati"
        loading={isLoading || isLoadingCount}
        onSortingChange={(key, order) =>
          setFilters({
            ...filters,
            sortBy: key as GetUsersSortBy,
            sortOrder: order,
          })
        }
        lastColumnRight
      />
      <PaginationRow
        page={filters.page!}
        pageLength={filters.pageLength!}
        totalPages={totalPages}
        onPageChange={(newPage) => setFilters({ ...filters, page: newPage })}
        onPageLengthChange={(newPageLength) =>
          setFilters({ ...filters, pageLength: newPageLength, page: 1 })
        }
      />
    </>
  );
}
