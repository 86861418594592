import { Fragment, useLayoutEffect, useState } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';

import { Divider, Group, Select, Text } from '@mantine/core';
import {
  IconBell,
  IconFileBarcode,
  IconFileEuro,
  IconFileInvoice,
  IconListDetails,
} from '@tabler/icons-react';

import { NotificationsFilter } from '@interfaces/notifications.interface';

import { generateDataNotifications } from '@api/mocks/norifications.mock';
import {
  NotificationsApiQueryParams,
  useGetNotificationsCountQuery,
  useGetNotificationsQuery,
  useModifyNotificationMutation,
} from '@api/notifications.api';

import { LayoutContextType } from '@components/layout/Layout';
import NotificationCard from '@components/notificationCard/NotificationCard';
import PaginationRow from '@components/PaginationRow';

export default function Notifications() {
  // ==========================================================================
  // General
  // ==========================================================================

  const [searchParams] = useSearchParams();

  // ==========================================================================
  // State
  // ==========================================================================
  const [filters, setFilters] = useState<NotificationsApiQueryParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 5),
    filter: 'notArchived',
  });

  // ==========================================================================
  // Api
  // ==========================================================================

  // Get initial notifications count
  const {
    data: notificationsCount = { count: 0 },
    isLoading: isLoadingNotificationsCount,
  } = useGetNotificationsCountQuery(filters.filter);

  const {
    data = generateDataNotifications(5),
    isLoading,
    error: errorNotifications,
  } = useGetNotificationsQuery(filters);

  const [modifyNotification] = useModifyNotificationMutation();

  // Map data
  const notifications = data.map((notification, index) => (
    <Fragment key={notification.id}>
      <NotificationCard
        title={notification.title}
        description={notification.description}
        read={notification.read}
        date={new Date(notification.createdAt)}
        icon={(() => {
          switch (notification.category) {
            case 'newDocumentDdt':
              return <IconFileBarcode />;
            case 'newDocumentInvoice':
              return <IconFileEuro />;
            case 'newDocumentOffer':
              return <IconFileInvoice />;
            case 'newOrder':
              return <IconListDetails />;

            default:
              return <IconBell />;
          }
        })()}
        // image={
        //   notification.category === 'orderStatusChange' ||
        //   notification.category === 'newOrder' ||
        //   notification.category === 'newGraphicsDraft'
        //     ? ordersIcon
        //     : notification.category === 'other'
        //       ? notificationIcon
        //       : documentsIcon
        // }
        onClick={() => {
          if (!notification.read) {
            modifyNotification({
              id: notification.id,
              body: { read: true },
            }).unwrap();
          }

          window.location.href = notification.link;
        }}
        loading={isLoading || isLoadingNotificationsCount}
      />
      {index !== data.length - 1 && <Divider />}
    </Fragment>
  ));

  // ==========================================================================
  // Render
  // ==========================================================================
  const { setLayoutProps } = useOutletContext<LayoutContextType>();

  useLayoutEffect(() => {
    setLayoutProps({ title: 'Notifiche' });
  }, [setLayoutProps]);

  const totalPages = Math.ceil(notificationsCount.count / filters.pageLength!);

  return (
    <>
      <Group mb="lg">
        <Select
          data={[
            { label: 'Tutte le notifiche', value: 'notArchived' },
            { label: 'Non lette', value: 'notRead' },
            { label: 'Lette', value: 'read' },
            { label: 'Archiviate', value: 'archived' },
          ]}
          value={filters.filter}
          onChange={(value) =>
            setFilters({ ...filters, filter: value as NotificationsFilter })
          }
        />
      </Group>
      {data.length > 0 && !errorNotifications ? (
        notifications
      ) : (
        <Text fw="bold" my="lg">
          Nessuna notifica presente per i filtri selezionati
        </Text>
      )}
      {notifications.length > 0 && (
        <PaginationRow
          page={filters.page!}
          pageLength={filters.pageLength!}
          totalPages={totalPages}
          onPageChange={(newPage) => setFilters({ ...filters, page: newPage })}
          onPageLengthChange={(newPageLength) =>
            setFilters({ ...filters, pageLength: newPageLength, page: 1 })
          }
        />
      )}
    </>
  );
}
