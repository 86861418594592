import { SortOrder } from '@domain/types';

import { CountResponse } from '@interfaces/common.interface';
import { Receipt } from '@interfaces/receipts.interface';

import api from './';

export type ReceiptsSortBy = 'id' | 'date';

export interface GetReceiptsRequestParams {
  sortBy?: ReceiptsSortBy;
  sortOrder?: SortOrder;
  searchQuery?: string;
  page?: number;
  pageLength?: number;
}

export const { useGetReceiptsQuery, useGetReceiptsCountQuery } =
  api.injectEndpoints({
    endpoints: (builder) => ({
      getReceipts: builder.query<Receipt[], GetReceiptsRequestParams>({
        query: (params) => ({
          url: 'receipts',
          params,
        }),
      }),
      getReceiptsCount: builder.query<CountResponse, string | undefined>({
        query: (searchQuery) => ({
          url: 'receipts/count',
          params: { searchQuery },
        }),
      }),
    }),
  });
