import { Button, Group, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { closeAllModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { handleSubmitError } from '@utils/forms';

import { ClientProfile } from '@interfaces/clientsProfiles.interface';

import { useUpdateClientProfileMutation } from '@api/clientsProfiles.api';

interface EditProfileInformationFormProps {
  clientProfile: ClientProfile;
}

export default function EditProfileInformationForm({
  clientProfile,
}: EditProfileInformationFormProps) {
  const initialValues = {
    phone: clientProfile.phone,
    ...(clientProfile.type === 'company'
      ? { sdi: clientProfile.sdi, pec: clientProfile.pec }
      : {}),
  };

  const form = useForm({
    initialValues,
  });

  const [updateClientProfile, { isLoading }] = useUpdateClientProfileMutation();

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await updateClientProfile(values).unwrap();

      closeAllModals();

      showNotification({
        title: 'Informazioni modificate',
        message:
          'Le informazioni del tuo account sono state modificate con successo',
      });
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <TextInput
        label="Telefono"
        placeholder="345 1213456"
        {...form.getInputProps('phone')}
      />
      {clientProfile.type === 'company' && (
        <>
          <TextInput
            mt="xs"
            label="Pec"
            placeholder="aziendasrl@pec.it"
            {...form.getInputProps('pec')}
          />
          <TextInput
            mt="xs"
            label="SDI"
            placeholder="SDI dell'azienda"
            {...form.getInputProps('sdi')}
          />
        </>
      )}
      {form.errors.general && (
        <Text c="red" size="sm" mt="xl">
          {form.errors.general}
        </Text>
      )}
      <Group mt="xl">
        <Button
          variant="default"
          onClick={() => closeAllModals()}
          loading={isLoading}
        >
          Annulla
        </Button>
        <Button type="submit" loading={isLoading}>
          Conferma
        </Button>
      </Group>
    </form>
  );
}
