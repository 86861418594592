import { createBrowserRouter } from 'react-router-dom';

import Layout from '@components/layout/Layout';
import PrivateRoute from '@components/PrivateRoute';
import RoleSpecificRoute from '@components/RoleSpecificRoute';

import Account from './Account';
import ActivateUser from './ActivateUser';
import AffiliatedOrderDetail from './AffiliatedOrderDetail';
import AffiliatedOrders from './AffiliatedOrders';
import CompleteRegistration from './CompleteRegistration';
import Coupons from './Coupons';
import Ddt from './Ddt';
import Invoices from './Invoices';
import Login from './Login';
import NotFound from './NotFound';
import Notifications from './Notifications';
import OfferDetail from './OfferDetail';
import Offers from './Offers';
import OrderDetail from './orderDetail/OrderDetail';
import OrderDetailWebRedirect from './OrderDetailWebRedirect';
import Orders from './Orders';
import PasswordReset from './PasswordReset';
import PasswordResetRequest from './PasswordResetRequest';
import Receipts from './Receipts';
import Register from './register/Register';
import ShippingCosts from './ShippingCosts';
import UserDetails from './userDetails/UserDetails';
import Users from './Users';

export const LOGIN_PATH = '/login';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Login />,
      },
      {
        path: 'password-reset',
        element: <PasswordResetRequest />,
      },
      {
        path: 'password-reset/:token',
        element: <PasswordReset />,
      },
      {
        path: 'completa-registrazione/:token',
        element: <CompleteRegistration />,
      },
      {
        path: 'users/activate/:token',
        element: <ActivateUser />,
      },
      {
        path: 'utenti',
        element: <RoleSpecificRoute roles={['admin']} element={<Users />} />,
      },
      {
        path: 'utenti/:id',
        element: (
          <RoleSpecificRoute roles={['admin']} element={<UserDetails />} />
        ),
      },
      {
        path: 'coupons',
        element: <RoleSpecificRoute roles={['admin']} element={<Coupons />} />,
      },
      {
        path: 'costi-spedizione',
        element: (
          <RoleSpecificRoute roles={['admin']} element={<ShippingCosts />} />
        ),
      },
      {
        path: 'registrati',
        element: <Register />,
      },
      {
        path: 'ordini',
        element: <RoleSpecificRoute roles={['client']} element={<Orders />} />,
      },
      {
        path: 'ordini/:id',
        element: (
          <RoleSpecificRoute roles={['client']} element={<OrderDetail />} />
        ),
      },
      {
        path: 'ordini/web/:id',
        element: (
          <RoleSpecificRoute
            roles={['client']}
            element={<OrderDetailWebRedirect />}
          />
        ),
      },
      {
        path: 'ordini-affiliati',
        element: (
          <RoleSpecificRoute
            roles={['seller']}
            element={<AffiliatedOrders />}
          />
        ),
      },
      {
        path: 'ordini-affiliati/:id',
        element: (
          <RoleSpecificRoute
            roles={['seller']}
            element={<AffiliatedOrderDetail />}
          />
        ),
      },
      {
        path: 'account',
        element: (
          <RoleSpecificRoute
            roles={['admin', 'client']}
            element={<Account />}
          />
        ),
      },
      {
        path: 'notifiche',
        element: <PrivateRoute element={<Notifications />} />,
      },
      {
        path: 'documenti/fatture',
        element: (
          <RoleSpecificRoute roles={['client']} element={<Invoices />} />
        ),
      },
      {
        path: 'documenti/ddt',
        element: <RoleSpecificRoute roles={['client']} element={<Ddt />} />,
      },
      {
        path: 'documenti/scontrini',
        element: (
          <RoleSpecificRoute roles={['client']} element={<Receipts />} />
        ),
      },
      {
        path: 'offerte',
        element: <RoleSpecificRoute roles={['client']} element={<Offers />} />,
      },
      {
        path: 'offerte/:id',
        element: (
          <RoleSpecificRoute roles={['client']} element={<OfferDetail />} />
        ),
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
]);

export default router;
