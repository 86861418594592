import { useSearchParams } from 'react-router-dom';

import { Group, Pagination, Select, Space } from '@mantine/core';

interface PaginationRowProps {
  page: number;
  pageLength: number;
  totalPages: number;
  onPageChange: (newPage: number) => void;
  onPageLengthChange: (newPageLength: number) => void;
  pageOptions?: number[];
}

export default function PaginationRow({
  page,
  pageLength,
  totalPages,
  onPageChange,
  onPageLengthChange,
  pageOptions = [5, 10, 20, 50],
}: PaginationRowProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handlePageChange = (newPage: number) => {
    setSearchParams({ ...searchParams, page: newPage.toString() });
    onPageChange(newPage);
  };

  const handlePageLengthChange = (newPageLength: number) => {
    setSearchParams({ ...searchParams, pageLength: newPageLength.toString() });
    onPageLengthChange(newPageLength);
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <Group justify="space-between" mt="xl">
      <Pagination
        style={{ visibility: totalPages > 1 ? 'visible' : 'hidden' }}
        value={page}
        onChange={handlePageChange}
        total={totalPages}
      />
      <Space />
      <Select
        allowDeselect={false}
        aria-label="Elementi per pagina"
        style={{ width: '4.5rem' }}
        data={pageOptions.map((opt) => opt.toString())}
        value={pageLength.toString()}
        onChange={(value) => handlePageLengthChange(+value!)}
      />
    </Group>
  );
}
