//   // Initial token refresh and ask for push notification permission
//   useEffect(() => {
//     const f = async () => {
//       try {
//         const { accessToken, userId } = await refresh().unwrap();

//         dispatch(setCredentials({ accessToken, user: { id: userId } }));

//         const token = await getPushNotificationsToken();

//         await modifyUser({ fcmToken: token }).unwrap();
//       } catch (e) {
//         // Nothing to do
//       } finally {
//         setLoading(false);
//       }
//     };

//     f();
//   }, [dispatch, refresh, modifyUser]);

//   onMessageListener((payload) => {
//     if (payload) {
//       showNotification({
//         title: payload.notification?.title,
//         message: payload.notification?.body,
//       });
//     }
//   });

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/carousel/styles.css';
import 'dayjs/locale/it';

import { H } from 'highlight.run';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import { Center, Loader, MantineProvider } from '@mantine/core';
import { DatesProvider } from '@mantine/dates';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';

import { useGetCurrentUserQuery } from '@api/auth.api';

import { setCredentials } from '@slices/auth.slice';

import router from '@routes/router';

import theme from './theme';

export default function App() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { data: user, isLoading: isLoadingGetUser } = useGetCurrentUserQuery();

  // Get user when page is reloaded
  useEffect(() => {
    if (!isLoadingGetUser) {
      if (user) {
        H.identify(user.email, {
          id: user.id,
          name: user.name,
        });

        dispatch(setCredentials({ user }));
      }
      setIsLoading(false);
    }
  }, [dispatch, user, isLoadingGetUser]);

  return (
    <MantineProvider theme={theme} forceColorScheme="light">
      <Notifications autoClose={4000} />
      <DatesProvider settings={{ locale: 'it' }}>
        <ModalsProvider
          modalProps={{
            styles: { title: { fontSize: '1.2rem', fontWeight: 'bold' } },
          }}
        >
          {isLoading ? (
            <Center h="100vh">
              <Loader />
            </Center>
          ) : (
            <>
              <RouterProvider router={router} />
            </>
          )}
        </ModalsProvider>
      </DatesProvider>
    </MantineProvider>
  );
}
