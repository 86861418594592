import {
  ActionIcon,
  Button,
  Checkbox,
  Group,
  MultiSelect,
  NumberInput,
  SegmentedControl,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { closeAllModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconRefresh } from '@tabler/icons-react';
import { handleSubmitError } from '@utils/forms';
import { generateRandomAlphabeticString } from '@utils/strings';

import { useGetAllCategoriesQuery } from '@api/categories.api';
import {
  Coupon,
  CouponValueType,
  useCreateCouponMutation,
  useUpdateCouponMutation,
} from '@api/coupons.api';
import { isApiError } from '@api/index';

interface CreateUpdateCouponModalProps {
  coupon?: Coupon;
}

export default function UpsertCouponModal({
  coupon,
}: CreateUpdateCouponModalProps) {
  // ==========================================================================
  // Api
  // ==========================================================================
  const [createCoupon, { isLoading: isLoadingCreate }] =
    useCreateCouponMutation();
  const [updateCoupon, { isLoading: isLoadingUpdate }] =
    useUpdateCouponMutation();
  const { data: categories = [], isLoading: isLoadingCategories } =
    useGetAllCategoriesQuery();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    code: coupon?.code || '',
    startDate: coupon?.startDate ? new Date(coupon.startDate) : new Date(),
    endDate: coupon?.endDate ? new Date(coupon.endDate) : (null as Date | null),
    value: coupon?.value || 0,
    couponValueType: coupon?.couponValueType || 'fixed',
    limitPerUser: coupon?.limitPerUser || (null as number | null),
    categoriesIds: coupon?.categories.map((c) => c.id) || [],
  };

  const form = useForm({
    initialValues,
  });

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onSubmit = async (values: typeof initialValues) => {
    try {
      if (coupon) {
        // Update
        await updateCoupon({
          code: coupon.code,
          body: {
            startDate: values.startDate,
            endDate: values.endDate,
            limitPerUser: values.limitPerUser,
            value: values.value,
            couponValueType: values.couponValueType as CouponValueType,
            categoriesIds: values.categoriesIds,
          },
        }).unwrap();

        showNotification({
          title: 'Coupon modificato',
          message: 'Coupon modificato con successo',
        });
      } else {
        // Create
        await createCoupon({
          ...values,
          couponValueType: values.couponValueType as CouponValueType,
        }).unwrap();

        showNotification({
          title: 'Coupon creato',
          message: 'Coupon creato con successo',
        });
      }

      closeAllModals();
    } catch (error) {
      if (isApiError(error) && error.status === 409) {
        form.setFieldError('code', 'Codice sconto già utilizzato');
        form.setFieldError('general', 'Uno o più campi contengono errori');
      } else {
        handleSubmitError(error, form);
      }
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <TextInput
        label="Codice"
        placeholder="Codice sconto"
        required
        {...form.getInputProps('code')}
        maxLength={10}
        onChange={(e) =>
          form.setFieldValue('code', e.currentTarget.value.toUpperCase())
        }
        rightSection={
          !coupon && (
            <Tooltip label="Genera codice casuale">
              <ActionIcon
                variant="subtle"
                onClick={() =>
                  form.setFieldValue('code', generateRandomAlphabeticString(5))
                }
              >
                <IconRefresh />
              </ActionIcon>
            </Tooltip>
          )
        }
        disabled={coupon !== undefined}
      />
      <DateTimePicker
        mt="xs"
        label="Data inizio validità"
        required
        minDate={new Date()}
        {...form.getInputProps('startDate')}
      />
      <DateTimePicker
        mt="xs"
        label="Data fine validità"
        clearable
        placeholder="Durata illimitata"
        minDate={form.values.startDate}
        {...form.getInputProps('endDate')}
      />
      <Group mt="xs" align="end">
        <NumberInput
          label="Valore sconto"
          prefix={form.values.couponValueType === 'fixed' ? '€ ' : ''}
          suffix={form.values.couponValueType === 'percentage' ? '%' : ''}
          min={0}
          max={form.values.couponValueType === 'percentage' ? 100 : undefined}
          clampBehavior="strict"
          decimalScale={2}
          {...form.getInputProps('value')}
        />
        <SegmentedControl
          data={[
            { label: 'Fisso', value: 'fixed' },
            { label: 'Percentuale', value: 'percentage' },
          ]}
          value={form.values.couponValueType}
          onChange={(value) => {
            if (value === 'percentage' && form.values.value > 100) {
              form.setFieldValue('value', 100);
            }
            form.setFieldValue('couponValueType', value as CouponValueType);
          }}
        />
      </Group>
      <Group mt="xs" align="end">
        <NumberInput
          label="Utilizzi massimi per utente"
          min={1}
          clampBehavior="strict"
          allowDecimal={false}
          disabled={form.values.limitPerUser === null}
          {...form.getInputProps('limitPerUser')}
        />
        <Checkbox
          label="Utilizzi illimitati"
          checked={form.values.limitPerUser === null}
          onChange={(e) => {
            form.setFieldValue(
              'limitPerUser',
              e.currentTarget.checked ? null : 1,
            );
          }}
        />
      </Group>

      <MultiSelect
        mt="xs"
        label="Categorie"
        searchable
        description="Lo sconto verrà applicato solo ai prodotti delle categorie selezionate"
        data={categories.map((category) => ({
          label: category.name,
          value: category.id,
        }))}
        disabled={isLoadingCategories}
        {...form.getInputProps('categoriesIds')}
      />

      {form.errors.general && (
        <Text c="red" size="sm" mt="xl">
          {form.errors.general}
        </Text>
      )}
      <Group mt="xl" justify="end">
        <Button type="submit" loading={isLoadingCreate || isLoadingUpdate}>
          Conferma
        </Button>
      </Group>
    </form>
  );
}
