import { useLayoutEffect, useState } from 'react';
import { Link, useOutletContext, useSearchParams } from 'react-router-dom';

import { ORDER_STATUS_COLORS, ORDER_STATUS_STRINGS } from '@domain/orderStatus';
import { ActionIcon, Badge, Group, Tooltip } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { IconInfoSquareRoundedFilled } from '@tabler/icons-react';
import { priceToDecimal } from '@utils/price';

import { useGetAffiliationCountQuery } from '@api/clientsProfiles.api';
import { generateGetAffiliatedOrdersMock } from '@api/mocks/orders.mock';
import {
  GetOrdersRequestParams,
  GetOrdersSortBy,
  useGetAffiliatedOrdersCountQuery,
  useGetAffiliatedOrdersQuery,
} from '@api/orders.api';

import { LayoutContextType } from '@components/layout/Layout';
import PaginationRow from '@components/PaginationRow';
import SearchBar from '@components/SearchBar';
import SortableTable from '@components/sortableTable/SortableTable';

export default function AffiliatedOrders() {
  const [searchParams] = useSearchParams();

  // ==========================================================================
  // State
  // ==========================================================================
  const [filters, setFilters] = useState<GetOrdersRequestParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 5),
    searchQuery: searchParams.get('search') || undefined,
  });

  const [searchQuery] = useDebouncedValue(filters.searchQuery, 200, {
    leading: true,
  });

  // ==========================================================================
  // Api
  // ==========================================================================

  const {
    data: affiliatedUsersCount = 0,
    isLoading: isLoadingAffiliatedUsersCount,
  } = useGetAffiliationCountQuery();

  // Get initial orders count
  const { data: ordersCount = { count: 0 }, isLoading: isLoadingOrdersCount } =
    useGetAffiliatedOrdersCountQuery(searchQuery);

  const { data = generateGetAffiliatedOrdersMock(), isLoading } =
    useGetAffiliatedOrdersQuery({
      ...filters,
      searchQuery,
    });

  // Map data
  const orders = data.map((order) => {
    const data = [
      new Date(order.date).toLocaleDateString('IT-it'),
      order.id,
      '€ ' + priceToDecimal(order.total),
      '€ ' + priceToDecimal(order.sellerCommission || 0),
      <Badge color={ORDER_STATUS_COLORS[order.status]}>
        {ORDER_STATUS_STRINGS[order.status]}
      </Badge>,
      <Tooltip label="Dettagli ordine">
        <ActionIcon
          variant="subtle"
          component={Link}
          to={`/ordini-affiliati/${order.id}`}
        >
          <IconInfoSquareRoundedFilled />
        </ActionIcon>
      </Tooltip>,
    ];

    return {
      key: order.id,
      data,
    };
  });

  // ==========================================================================
  // Render
  // ==========================================================================
  const { setLayoutProps } = useOutletContext<LayoutContextType>();

  useLayoutEffect(() => {
    if (!isLoadingAffiliatedUsersCount) {
      setLayoutProps({
        title: `Lista ordini utenti affiliati (${affiliatedUsersCount} utenti affiliati)`,
      });
    } else {
      setLayoutProps({
        title: 'Lista ordini utenti affiliati',
      });
    }
  }, [setLayoutProps, isLoadingAffiliatedUsersCount]);

  const totalPages = Math.ceil(ordersCount.count / filters.pageLength!);

  return (
    <>
      <Group mb="lg">
        <SearchBar
          placeholder="Cerca numero ordine"
          value={filters.searchQuery}
          onChange={(newValue) =>
            setFilters({ ...filters, searchQuery: newValue })
          }
        />
      </Group>
      <SortableTable
        data={orders}
        headings={{
          date: 'Data',
          id: 'Numero ordine',
          total: 'Totale',
          commission: 'Provvigione',
          status: 'Stato',
          actions: '',
        }}
        sortableKeys={['id', 'date']}
        onSortingChange={(key, order) =>
          setFilters({
            ...filters,
            sortBy: key as GetOrdersSortBy,
            sortOrder: order,
          })
        }
        emptyText="Nessun ordine trovato per i filtri selezionati"
        loading={isLoading || isLoadingOrdersCount}
        lastColumnRight
        minWidth="50rem"
      />
      <PaginationRow
        page={filters.page!}
        pageLength={filters.pageLength!}
        totalPages={totalPages}
        onPageChange={(newPage) => setFilters({ ...filters, page: newPage })}
        onPageLengthChange={(newPageLength) =>
          setFilters({ ...filters, pageLength: newPageLength, page: 1 })
        }
      />
    </>
  );
}
