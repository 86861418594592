import { ReactNode } from 'react';

import { ActionIcon, Group, Paper, Title, Tooltip } from '@mantine/core';

import classes from './Card.module.css';

interface CardProps {
  title?: string;
  children?: ReactNode;
  action?: {
    title: string;
    icon: ReactNode;
    onClick: () => void;
  };
  [x: string]: unknown;
}

export default function Card({
  title,
  children,
  action,
  ...otherProps
}: CardProps) {
  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <Paper
      p="xl"
      {...otherProps}
      className={(otherProps.className || '') + ' ' + classes.informationCard}
    >
      {title && (
        <Group wrap="nowrap" mb="lg" justify="space-between">
          <Title order={2}>{title}</Title>
          {action && (
            <Tooltip label={action.title}>
              <ActionIcon onClick={action.onClick}>{action.icon}</ActionIcon>
            </Tooltip>
          )}
        </Group>
      )}
      {children}
    </Paper>
  );
}
