import api from './';
import { Provincia } from './locations.api';

export interface ShippingCost {
  id: number;
  priority: number;
  value: number;
  minOrderValue: number | null;
  provinces: Provincia[] | null;
}

export interface CreateShippingCostRequestBody {
  value: number;
  minOrderValue?: number | null;
  provincesCodes: string[];
}

export type UpdateShippingCostRequestBody =
  Partial<CreateShippingCostRequestBody>;

export interface UpdateShippingCostRequest {
  id: number;
  body: UpdateShippingCostRequestBody;
}

export const {
  useGetShippingCostsQuery,
  useCreateShippingCostMutation,
  useUpdateShippingCostMutation,
  useDeleteShippingCostMutation,
  useIncrementShippingCostPriorityMutation,
  useDecrementShippingCostPriorityMutation,
} = api.injectEndpoints({
  endpoints: (builder) => ({
    getShippingCosts: builder.query<ShippingCost[], void>({
      query: () => ({
        url: '/shipping-costs',
      }),
      providesTags: ['shippingCosts'],
    }),
    createShippingCost: builder.mutation<
      ShippingCost[],
      CreateShippingCostRequestBody
    >({
      query: (body) => ({
        url: '/shipping-costs',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['shippingCosts'],
    }),
    updateShippingCost: builder.mutation<
      ShippingCost,
      UpdateShippingCostRequest
    >({
      query: ({ id, body }) => ({
        url: `/shipping-costs/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['shippingCosts'],
    }),
    deleteShippingCost: builder.mutation<ShippingCost, number>({
      query: (id) => ({
        url: `/shipping-costs/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['shippingCosts'],
    }),
    incrementShippingCostPriority: builder.mutation<void, number>({
      query: (id) => ({
        url: `/shipping-costs/${id}/increment-priority`,
        method: 'POST',
      }),
      invalidatesTags: ['shippingCosts'],
    }),
    decrementShippingCostPriority: builder.mutation<void, number>({
      query: (id) => ({
        url: `/shipping-costs/${id}/decrement-priority`,
        method: 'POST',
      }),
      invalidatesTags: ['shippingCosts'],
    }),
  }),
});
