import { generateMockValue } from '../../utils/generateMockData';

export function generateGetSingleOfferMock() {
  return generateMockValue({
    type: 'object',
    values: {
      id: 'string',
      date: 'string',
      pdfName: 'string',
      paymentType: 'PaymentType',
      confirmationDate: 'string',
      userId: 'number',
      totalPrice: 'number',
    },
  });
}

export function generateGetOffersMock() {
  return generateMockValue({
    type: 'array',
    minLength: 5,
    maxLength: 5,
    each: {
      type: 'object',
      values: {
        id: 'string',
        date: 'string',
        pdfName: 'string',
        paymentType: 'PaymentType',
        confirmationDate: 'string',
        userId: 'number',
      },
    },
  });
}

export function generateGetOffersProductsMock() {
  return generateMockValue({
    type: 'array',
    minLength: 5,
    maxLength: 5,
    each: {
      type: 'object',
      values: {
        id: 'string',
        sku: 'string',
        name: 'string',
        imageUrl: 'string',
        color: 'string',
        size: 'string',
        quantity: 'number',
        totalPrice: 'number',
        salePercent: 'number',
      },
    },
  });
}
