import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { UserRole } from '@interfaces/auth.interface';

import { selectCurrentUser } from '@slices/auth.slice';

const useUserRoleGuard = (roles: UserRole | UserRole[]) => {
  const user = useSelector(selectCurrentUser);

  const hasRole = Array.isArray(roles)
    ? roles.includes(user!.role)
    : user?.role === roles;

  return useMemo(() => hasRole, [hasRole]);
};

export default useUserRoleGuard;
