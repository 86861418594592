import { Button, Group, Select, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { closeAllModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { handleSubmitError } from '@utils/forms';

import { useCreatePasswordlessUserMutation } from '@api/users.api';

export default function CreatePasswordlessUserModal() {
  // ==========================================================================
  // General
  // ==========================================================================

  // ==========================================================================
  // State
  // ==========================================================================

  // ==========================================================================
  // Api
  // ==========================================================================
  const [createUser, { isLoading }] = useCreatePasswordlessUserMutation();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    email: '',
    name: '',
    role: 'admin',
  };

  const form = useForm({
    initialValues,
  });

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onSubmit = async (values: typeof initialValues) => {
    try {
      await createUser({
        ...values,
        role: values.role as 'admin' | 'seller',
      }).unwrap();

      showNotification({
        title: 'Utente creato',
        message: "L'utente riceverà un'email per completare la registrazione",
      });

      closeAllModals();
    } catch (error) {
      handleSubmitError(error, form);
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <Select
        label="Ruolo"
        data={[{ label: 'Admin', value: 'admin' }]}
        {...form.getInputProps('role')}
      />
      <TextInput
        mt="xs"
        label="Email"
        placeholder="Email"
        {...form.getInputProps('email')}
      />
      <TextInput
        mt="xs"
        label="Nominativo"
        placeholder="Nominativo"
        {...form.getInputProps('name')}
      />
      {form.errors.general && (
        <Text c="red" size="sm" mt="xl">
          {form.errors.general}
        </Text>
      )}
      <Group mt="xl" justify="end">
        <Button type="submit" loading={isLoading}>
          Conferma
        </Button>
      </Group>
    </form>
  );
}
