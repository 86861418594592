import { SortOrder } from '@domain/types';

import { CountResponse } from '@interfaces/common.interface';
import { Ddt } from '@interfaces/ddt.interface';

import api from './';

export type DdtSortBy = 'id' | 'date';

export interface GetDdtRequestParams {
  sortBy?: DdtSortBy;
  sortOrder?: SortOrder;
  searchQuery?: string;
  page?: number;
  pageLength?: number;
}

export const { useGetDdtQuery, useGetDdtCountQuery } = api.injectEndpoints({
  endpoints: (builder) => ({
    getDdt: builder.query<Ddt[], GetDdtRequestParams>({
      query: (params) => ({
        url: 'ddt',
        params,
      }),
    }),
    getDdtCount: builder.query<CountResponse, string | undefined>({
      query: (searchQuery) => ({
        url: 'ddt/count',
        params: { searchQuery },
      }),
    }),
  }),
});
