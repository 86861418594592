import { Link, Navigate, useNavigate } from 'react-router-dom';

import { Anchor, Button, Text, TextInput, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { handleSubmitError } from '@utils/forms';

import { usePasswordResetRequestMutation } from '@api/passwordReset.api';

import useAuth from '@hooks/useAuth';
import useGRecaptcha from '@hooks/useGRecaptcha';

import FormsPaper from '@components/FormsPaper';

export default function PasswordResetRequest() {
  // ==========================================================================
  // General
  // ==========================================================================
  const { isAuthenticated } = useAuth();

  const navigate = useNavigate();

  const { getRecaptchaToken } = useGRecaptcha();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    email: '',
  };

  const form = useForm({
    initialValues,
  });

  const onSubmit = async (values: typeof initialValues) => {
    try {
      const recaptchaToken = await getRecaptchaToken('PasswordResetRequest');

      await passwordResetRequest({ body: values, recaptchaToken }).unwrap();

      showNotification({
        title: 'Richiesta ripristino password confermata!',
        message:
          'Se la email che hai inserito è corretta riceverai una email con le istruzioni per ripristinare la tua password.',
      });

      navigate('/');
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  // ==========================================================================
  // Api
  // ==========================================================================
  const [passwordResetRequest, { isLoading }] =
    usePasswordResetRequestMutation();

  // ==========================================================================
  // Render
  // ==========================================================================
  return isAuthenticated ? (
    <Navigate to="/ordini" replace={true} />
  ) : (
    <>
      <Text ta="center">
        Sai già la tua password?{' '}
        <Anchor to="/" component={Link}>
          Accedi
        </Anchor>
      </Text>

      <FormsPaper mt={30}>
        <form
          onSubmit={form.onSubmit((values) => {
            onSubmit(values);
          })}
        >
          <Title order={2} ta="center" mb="lg">
            Recupera password
          </Title>
          <Text ta="center" size="sm" mb="lg">
            Inserisci l'email con la quale ti sei registrato e riceverai un link
            per ripristinare la tua password.
          </Text>
          <TextInput
            label="Email"
            placeholder="email@example.com"
            required
            {...form.getInputProps('email')}
          />
          {form.errors.general && (
            <Text c="red" size="sm" mt="xl">
              {form.errors.general}
            </Text>
          )}
          <Button type="submit" fullWidth mt="xl" loading={isLoading}>
            Recupera password
          </Button>
        </form>
      </FormsPaper>
    </>
  );
}
